import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { API } from 'lib/rest';
import { AccountConnexions, AccountNew, AccountComment, AccountTypeIcon, AccountPersonnalInformations, AccountDateFormat, LicenseType, LicenseExpiration, LicenseKey, LicenseDevices, ActivityLog, AccountCard } from 'lib/utils';
import { ENDPOINTS } from 'config/network';

var updateTO;

export default class Admin extends Component {

  state = {
    loading: true,
    accountLoading: false,
    accounts: [],
    account: null,
    filter: {
      search: '',
      ghosts: false,
      types: {
        PRIVATE: true,
        TEACHER: true,
        SCHOOL: true,
        TOWNHALL: true,
        ADMIN: true,
        OTHER: true,
      },
      page: 1,
      lastPage: 1,
      total: 0,
      filtered: 0,
      offset: 0,
      limit: 24
    },
    licenseCount: 0,
  };

  componentDidMount() {
    this.getData();
  }

  filter = (f, delay = 0) => {
    this.setState({
      accounts: [],
      loading: true,
      filter: Object.assign(this.state.filter, f)
    });
    clearTimeout(updateTO)
    updateTO = setTimeout(() => this.getData(), delay)
  }
  filterTypes = (t, delay = 0) => {
    var filter = this.state.filter
    filter.types = Object.assign(filter.types, t)
    this.setState({
      accounts: [],
      loading: true,
      filter
    });
    clearTimeout(updateTO)
    updateTO = setTimeout(() => this.getData(), delay)
  }

  resetPassword = (email) => {
    if (this.props.account.type !== 'ADMIN') {
      this.props.history.push('/home');
      return;
    } else {
      API.post(ENDPOINTS.forget, this.props.token, {
        email: email
      }).then(() => {
        var account = this.state.account;
        this.closeModal()
        this.openModal(account)
      });
    }
  }

  loginAs = (email) => {
    if (this.props.account.type !== 'ADMIN') {
      this.props.history.push('/home');
      return;
    } else {
      API.post(ENDPOINTS.adminLogin, this.props.token, {
        email: email
      }).then((response) => {
        this.props.setToken(response);
        this.props.history.push('/home');
      });
    }
  }

  closeModal = () => {
    this.setState({ account: null });
  }

  editModal = (tag) => {
    this.props.history.push('/home/admin/account/' + tag);
  }

  openModal = (account) => {
    this.setState({ account: account });
    if (this.props.account.type !== 'ADMIN') {
      this.props.history.push('/home');
      return;
    } else {
      API.get(ENDPOINTS.account, this.props.token, {
        id: account.id
      }).then(result => {
        if (result.account) {
          this.setState({ account: result.account });
        }
      });
    }
  }

  getData = () => {
    if (this.props.account.type !== 'ADMIN') {
      this.props.history.push('/home');
      return;
    } else {
      API.post(ENDPOINTS.accounts, this.props.token, this.state.filter).then(result => {
        let account = null;
        if (this.state.account)
          account = result.data.find(
            c => c.id === this.state.account.id,
          );
        this.setState({
          filter: Object.assign(this.state.filter, {
            page: parseInt(result.page),
            lastPage: parseInt(result.lastPage),
            total: parseInt(result.total),
            filtered: parseInt(result.filtered),
            offset: parseInt(result.offset),
            limit: parseInt(result.limit)
          }),
          accounts: result.data,
          loading: false,
          accountLoading: false,
          account,
        });
      });
    }
  };
  addAccount = () => this.props.history.push('/home/admin/account/new')

  renderFilter = () =>
    <Form.Row>
      <Form.Group as={Col}>
        <Form.Check
          type="switch"
          inline
          id='ghosts'
          label={AccountConnexions(false)}
          checked={this.state.filter.ghosts}
          onChange={e => this.filter({ ghosts: e.target.checked }, 1000)}
        /><br />
        {AccountNew(this.addAccount)}
      </Form.Group>
      <Form.Group as={Col} sm="10" md="6">
        <Form.Control
          placeholder="Rechercher"
          value={this.state.filter.search}
          onChange={e => this.filter({ search: e.target.value }, 1000)}
        />
      </Form.Group>
      <Form.Group as={Col} style={{ textAlign: 'right' }}>
        <Form.Check
          type="switch"
          inline
          id='type_private'
          label={AccountTypeIcon('PRIVATE')}
          checked={this.state.filter.types.PRIVATE}
          onChange={e => this.filterTypes({ PRIVATE: e.target.checked }, 1000)}
        />
        <Form.Check
          type="switch"
          inline
          id='type_teacher'
          label={AccountTypeIcon('TEACHER')}
          checked={this.state.filter.types.TEACHER}
          onChange={e => this.filterTypes({ TEACHER: e.target.checked }, 1000)}
        />
        <Form.Check
          type="switch"
          inline
          id='type_school'
          label={AccountTypeIcon('SCHOOL')}
          checked={this.state.filter.types.SCHOOL}
          onChange={e => this.filterTypes({ SCHOOL: e.target.checked }, 1000)}
        />
        <Form.Check
          type="switch"
          inline
          id='type_townhall'
          label={AccountTypeIcon('TOWNHALL')}
          checked={this.state.filter.types.TOWNHALL}
          onChange={e => this.filterTypes({ TOWNHALL: e.target.checked }, 1000)}
        />
        <Form.Check
          type="switch"
          inline
          id='type_admin'
          label={AccountTypeIcon('ADMIN')}
          checked={this.state.filter.types.ADMIN}
          onChange={e => this.filterTypes({ ADMIN: e.target.checked }, 1000)}
        />
        <Form.Check
          type="switch"
          inline
          id='type_other'
          label={AccountTypeIcon('OTHER')}
          checked={this.state.filter.types.OTHER}
          onChange={e => this.filterTypes({ OTHER: e.target.checked }, 1000)}
        />
      </Form.Group>
    </Form.Row>

  renderModal = (account) =>
    <Modal
      show={true}
      onHide={() => this.closeModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {AccountTypeIcon(account.type)} #{account.tag}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            {AccountPersonnalInformations(account)}
          </Col>
          <Col style={{ textAlign: "right", fontStyle: "italic" }}>
            <p>{AccountDateFormat(account.creation_date, true)}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Identifiants ({account.connexions.length}):</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            {AccountConnexions(account.connexions, true, this.resetPassword, this.loginAs)}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Profils ({Array.isArray(account.students) ? account.students.length : 0}/{account.max_students}):</h5>
          </Col>
        </Row>
        <Row>
          {!account.students && <Col>Chargement...</Col>}
          {Array.isArray(account.students) && account.students.map((student, i) =>
            <Col key={i} sm='12' md='6' lg='4' xl='3' >
              <ListGroup.Item
                key={student.id}
                type="button"
                style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem 1rem', textAlign: 'center' }}
              >
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}><b>{student.name}</b></div>
              </ListGroup.Item>
            </Col>)}

        </Row>
        <Row>
          <Col>
            <h5>Licences ({Array.isArray(account.licenses) ? account.licenses.length : 0}):</h5>
          </Col>
        </Row>
        <Row>
          {!account.licenses && <Col>Chargement...</Col>}
          {Array.isArray(account.licenses) && account.licenses.map((license, i) =>
            <Col key={i} sm='12' md='6' lg='4' xl='3' >
              <ListGroup.Item
                key={license.id}
                type="button"
                style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem 1rem', textAlign: 'center' }}
              >
                <span>{LicenseType(license)} {LicenseExpiration(license)} {LicenseKey(license)} {LicenseDevices(license)}</span>
              </ListGroup.Item>
            </Col>)}
        </Row>
        <Row>
          <Col>
            <h5>Commentaires internes :</h5>
          </Col>
        </Row>
        <Row>
          {!account.comments && <Col>Chargement...</Col>}
          <Col>
            <Table striped bordered hover size="sm">
              <tbody>
                {Array.isArray(account.comments) && account.comments.map((comment, i) =>
                  AccountComment(comment, i)
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Activités récentes :</h5>
          </Col>
        </Row>
        <Row>
          {!account.logs && <Col>Chargement...</Col>}
          <Col>
            <Table striped bordered hover size="sm">
              <tbody>
                {Array.isArray(account.logs) && account.logs.map((log, i) =>
                  ActivityLog(log, i)
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => this.editModal(account.tag)}>Editer</Button>
      </Modal.Footer>
    </Modal>

  renderPagination() {
    let items = []
    if (this.state.filter.page === 1) {
      items.push(<Pagination.First key="<<" disabled />)
      items.push(<Pagination.Prev key="<" disabled />)
    } else {
      items.push(<Pagination.First key="<<" onClick={e => this.filter({ page: 1 })} />)
      items.push(<Pagination.Prev key="<" onClick={e => this.filter({ page: this.state.filter.page - 1 })} />)
    }

    for (let current = Math.max(1, this.state.filter.page - 2); current <= Math.min(this.state.filter.lastPage, this.state.filter.page + 2); current++) {
      items.push(
        <Pagination.Item key={current} onClick={e => this.filter({ page: current })} active={current === this.state.filter.page}>
          {current}
        </Pagination.Item>
      );
    }

    if (this.state.filter.page === this.state.filter.lastPage) {
      items.push(<Pagination.Next key=">" disabled />)
      items.push(<Pagination.Last key=">>" disabled />)
    } else {
      items.push(<Pagination.Next key=">" onClick={e => this.filter({ page: this.state.filter.page + 1 })} />)
      items.push(<Pagination.Last key=">>" onClick={e => this.filter({ page: this.state.filter.lastPage })} />)
    }

    return <Row>
      <Col>
        <Pagination size="sm" style={{ margin: 0 }}>
          {items}
        </Pagination>
      </Col>
      <Col style={{ textAlign: 'right' }}><span>Résultats de {Math.min(this.state.filter.filtered, this.state.filter.offset + 1)} à {Math.min(this.state.filter.filtered, (this.state.filter.page) * this.state.filter.limit)} sur {this.state.filter.filtered} (Total : {this.state.filter.total})</span></Col>
    </Row>
  }

  render() {

    return (
      <Row className="homePadding">
        <Col sm>
          <Card>
            <Card.Header>
              {this.renderFilter()}
            </Card.Header>
            <Card.Body>
              {this.state.loading && <h6>Chargement...</h6>}
              <ListGroup
                variant="flush"
                style={{ maxHeight: '68vh', overflowX: 'hidden', overflowY: 'auto' }}
              >
                <Row style={{ margin: '0' }}>
                  {this.state.accounts.map((item, i) => <AccountCard key={i} account={item} index={i} admin={this} />)}
                </Row>
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              {this.renderPagination()}
            </Card.Footer>
          </Card>
        </Col>
        {this.state.account && this.renderModal(this.state.account)}
      </Row>
    );
  }
}

Admin.propTypes = {
  token: PropTypes.string,
  history: PropTypes.object,
};
