export const API_URL = process.env.REACT_APP_API_URL;
export const SITE_URL = process.env.REACT_APP_SITE_URL;

export const ENDPOINTS = {
  status: '/status',
  login: '/login',
  adminLogin: '/secure/admin/login',
  forget: '/mail_forget',
  checkLink: '/resetLinkExists',
  reset: '/resetPassword',
  register: '/mail_register',
  exists: '/mail_check',
  students: '/secure/students',
  groups: '/secure/groups',
  studentsEval: '/secure/students_eval',
  studentsExercises: '/secure/students_exercises',
  studentAdd: '/secure/student_add',
  groupAdd: '/secure/group_add',
  studentData: '/secure/student_data',
  groupData: '/secure/group_data',
  studentRemove: '/secure/student_remove',
  groupRemove: '/secure/group_remove',
  studentAccountUnlink: '/secure/student_account_unlink',
  studentAccountLink: '/secure/student_account_link',
  studentAccountLinkEmail: '/secure/student_account_link_email',
  studentAccountChangeOwner: '/secure/student_account_change_owner',
  groupAccountUnlink: '/secure/group_account_unlink',
  groupAccountLink: '/secure/group_account_link',
  groupAccountLinkEmail: '/secure/group_account_link_email',
  groupAccountChangeOwner: '/secure/group_account_change_owner',
  studentInfoUpdate: '/secure/info/update',
  groupUpdate: '/secure/group_update',
  deviceRename: '/secure/device_rename',
  deviceRemove: '/secure/device_remove',
  passwordChange: '/secure/password_change',
  checkout: '/secure/checkout',
  checkoutBook: '/secure/checkoutBook',
  directCheckoutBook: '/checkoutBook',
  notifyBookBuy: '/notifyBookBuy',
  cancelSub: '/secure/cancelSub',
  licenses: '/secure/licenses',
  plans: '/secure/plans',
  mail: '/secure/mail',
  userinfo: '/secure/userinfo',
  //accountType: '/secure/accountType',
  checkTag: '/tag_check',
  activateDevice: '/secure/activateDevice',
  codes: '/secure/code',
  delCode: '/secure/delCode',
  delPro: '/secure/delPro',
  account: '/secure/admin/account',
  difficulties: '/secure/admin/difficulties',
  phonemes: '/secure/admin/phonemes',
  accountConnexions: '/secure/admin/account_connexions',
  accountLicenses: '/secure/admin/account_licenses',
  accountStudents: '/secure/admin/account_students',
  accountComments: '/secure/admin/account_comments',
  accounts: '/secure/admin/accounts',
  proClients: '/secure/admin/proClients',
  createProLicense: '/secure/admin/createProLicense',
  deleteProLicenses: '/secure/admin/deleteProLicenses',
};

export const PHONEMES = [
  "é",
  "(f|F)",
  "(i|î)",
  "(j|J)",
  "(l|L)",
  "(m|M)",
  "(o|ô)",
  "(r|R)",
  "(u|û)",
  "(b|B)",
  "(e|E)",
  "(n|N)",
  "on",
  "ou",
  "(p|P)",
  "(t|T)",
  "(v|V)",
  "(an|en)",
  "(au|eau)",
  "(c|qu)",
  "(ch|Ch)",
  "(d|D)",
  "(oi|oî)",
  "(s|z)",
  "ain",
  "(è|ê|ai[^nm]?|err?|ess)",
  "(er|ez|et)",
  "(eu|œu)",
  "(g|gu)",
  "(ge|gi|gé|gè|gê|gî)",
  "in",
  "(s|S|ss|ce|ci)",
  "(un|um|ein|aim|ym)",
  "gn",
  "(ill|ie|hi|ay|io|ail|y)",
  "oin",
  "(a|â|A)"
]