import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import 'react-datepicker/dist/react-datepicker.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';

import { API } from 'lib/rest';
import { AccountTypeIcon, EmailCheck } from 'lib/utils';
import { ENDPOINTS } from 'config/network';


import { GroupTypeIcon } from 'lib/utils';

const AccountRow = (account, i, unlink, changeOwner) => {
  let name = "-";
  if (account.personnal_info) {
    try {
      const info = account.personnal_info;
      if (info.name && info.name !== '') {
        name = `${info.name} ${info.surname}`;
      }
    } catch (e) {
      console.error(e);
    }
  }
  var link
  if (unlink) {
    link = <><OverlayTrigger overlay={<Tooltip>Nommer propriétaire du groupe</Tooltip>}>
      <span className='text-success' style={{ cursor: "pointer", float: "right", paddingLeft: '8px' }} onClick={() => changeOwner(account)}><FontAwesomeIcon icon={['fas', 'user-shield']} /></span>
    </OverlayTrigger><OverlayTrigger overlay={<Tooltip>Supprimer l'autorisation de ce compte</Tooltip>}>
        <span className='text-danger' style={{ cursor: "pointer", float: "right", paddingLeft: '8px' }} onClick={() => unlink(account)}><FontAwesomeIcon icon={['fas', 'user-times']} /></span>
      </OverlayTrigger></>
  } else {
    link = <OverlayTrigger overlay={<Tooltip>Propriétaire du groupe</Tooltip>}>
      <span className='text-warning' style={{ float: "right" }}><FontAwesomeIcon icon={['fas', 'user-shield']} /></span>
    </OverlayTrigger>
  }
  return <tr key={i} style={{ borderBottom: '1px solid #ccc' }}><td>{AccountTypeIcon(account.type)}</td><td><b>#{account.tag}</b></td><td>{name}</td><td>{Array.isArray(account.connexions) && account.connexions.map((connexion, j) => connexion.identifier)}</td><td>{link}</td></tr>
}

export default class GroupEditModal extends Component {
  state = {
    id: null,
    error: false,
    type: 'GROUP'
  };

  setup = async () => {
    if (this.props.add) {
      this.setState(
        produce(draft => {
          draft.error = false;
          draft.id = null;
          draft.type = this.props.group.type
          draft.max_students = this.props.group.max_students
          draft.parent_id = this.props.group.parent_id;
        }),
      );
    } else {
      this.setState(
        produce(draft => {
          draft.accounts = null;
          draft.id = this.props.group.id
          draft.name = this.props.group.name
          draft.type = this.props.group.type
          draft.max_students = this.props.group.max_students
        })
      );
      const response = await API.get(ENDPOINTS.groupData, this.props.token, {
        group_id: this.props.group.id
      })
      if (response && response.code === 'OK') {
        this.setState(
          produce(draft => {
            draft.parent_id = response.group.parent_id
            draft.owner = response.group.owner
            draft.accounts = response.group.accounts
          })
        );
      }
    }
  };

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };

  saveGroup = (evt) => {
    evt.preventDefault()
    if (!this.state.name || this.state.name.trim() === '' || this.state.max_students <= 0) {
      this.setState(
        produce(draft => {
          draft.error = true;
        }),
      );
    } else {
      const {
        id,
        name,
        type,
        max_students,
        parent_id
      } = this.state;

      this.props.saveGroup({
        id,
        name,
        type,
        max_students,
        parent_id
      });
    }
  };

  linkAccountTag = async () => {
    const response = await API.post(
      ENDPOINTS.groupAccountLink,
      this.props.token,
      {
        group_id: this.state.id,
        tag: document.getElementById('add_account_tag').value
      },
    );
    if (response && response.code === 'FORBIDDEN') {
      document.location.reload()
    } else if (response && response.code === 'OK') {
      this.setup()
    } else {
      alert('Tag inconnu, veuillez vérifier.')
    }
  };

  linkAccountEmail = async () => {
    if (!EmailCheck(document.getElementById('add_account_email').value)) {
      alert('Veuillez saisir un email valide')
    } else {
      const response = await API.post(
        ENDPOINTS.groupAccountLinkEmail,
        this.props.token,
        {
          group_id: this.state.id,
          email: document.getElementById('add_account_email').value
        },
      );
      if (response && response.code === 'FORBIDDEN') {
        document.location.reload()
      } else if (response && (response.code === 'OK' || response.code === 'CREATED')) {
        this.setup()
      } else {
        alert('Erreur, veuillez contacter le support.')
      }
    }
  };

  unlinkAccount = async (account) => {
    const ask = (this.props.account.id === account.id) ? "Attention : Vous êtes sur le point de supprimer votre propre accès à ce groupe. Il ne sera donc plus disponible sur votre compte. Êtes-vous sûr de vouloir continuer ?" : "Attention : Vous êtes sur le point de supprimer l'accès du compte #" + account.tag + " à ce profil. Êtes-vous sûr de vouloir continuer ?"
    if (window.confirm(ask)) {
      const response = await API.post(
        ENDPOINTS.groupAccountUnlink,
        this.props.token,
        {
          group_id: this.state.id,
          account_id: account.id
        },
      );
      if (response && response.code === 'DELETED') {
        if (this.props.account.id === account.id) {
          document.location.reload()
        } else {
          this.setup()
        }
      }
    }
  };
  changeOwnerAccount = async (account) => {
    const ask = "Attention : Vous êtes sur le point de changer le propriétaire du groupe. Il ne sera donc plus éditable sur votre compte. Êtes-vous sûr de vouloir continuer ?"
    if (window.confirm(ask)) {
      const response = await API.post(
        ENDPOINTS.groupAccountChangeOwner,
        this.props.token,
        {
          group_id: this.state.id,
          account_id: account.id
        },
      );
      if (response && response.code === 'OK') {
        this.setup()
      }
    }
  };

  deleteGroup = async () => {
    if (window.confirm("Attention : si vous supprimez ce groupe il ne sera plus accessible sur aucun compte et ses données seront définitivement perdue, voulez-vous quand même continuer ?")) {
      const response = await API.post(
        ENDPOINTS.groupRemove,
        this.props.token,
        {
          id: this.state.id
        },
      );
      if (response && response.code === 'DELETED') {
        document.location.reload();
      }
    }
  };



  render() {
    var errorMessage = 'Les champs rouges sont obligatoires';
    var disabled = (!this.props.add);
    if (this.state.owner) {
      if (this.state.owner.id === this.props.account.id) {
        disabled = false
      } else {
        errorMessage = "Vous n'êtes pas le propriétaire de ce groupe"
      }
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        onEnter={this.setup}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{GroupTypeIcon(this.state.type)}
            {this.props.add
              ? 'Nouveau groupe'
              : ` ${this.state.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.saveGroup}>{(this.state.error || (this.state.owner && disabled)) && (
            <Form.Group controlId="formError">
              <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>
            </Form.Group>
          )}
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Type"
                    value={this.state.type}
                    onChange={this.onChange('type')}
                    disabled={disabled}
                  >
                    <option value="GROUP">groupe</option>
                    <option value="CLASS">classe</option>
                    <option value="SCHOOL">école</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre d'élèves maximum dans le groupe</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    value={this.state.max_students}
                    onChange={this.onChange('max_students')}
                    isInvalid={this.state.max_students <= 0}
                    disabled={disabled}
                    required={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nom du groupe</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Nom du groupe"
                    value={this.state.name}
                    onChange={this.onChange('name')}
                    isInvalid={this.state.error}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              {this.props.availableGroups && this.props.availableGroups.length > 1 && (
                <Col>
                  <Form.Group>
                    <Form.Label>Déplacer vers le groupe :</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Groupe parent"
                      value={this.state.parent_id ? this.state.parent_id : ""}
                      onChange={this.onChange('parent_id')}
                      disabled={disabled}
                    >
                      <option value=""> - </option>
                      {this.props.availableGroups.map((group, i) => {
                        if (this.state.id !== group.id) {
                          return <option key={i} value={group.id}>{group.name}</option>
                        }
                        return null
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>)}
            </Row>
          </Form>
          <hr />
          {!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) && <Row>
            <Col sm='12'>
              {!this.props.add && <h6>Comptes ayant accès à ce groupe :</h6>}
              {!this.props.add && !this.state.accounts && <>Chargement des comptes autorisés...</>}

              <Table striped bordered hover size="sm">
                <tbody>
                  {this.state.owner && AccountRow(this.state.owner, 0)}
                  {Array.isArray(this.state.accounts) && this.state.accounts.map((account, i) =>
                    AccountRow(account, i, this.unlinkAccount, this.changeOwnerAccount)
                  )}
                </tbody>
              </Table>
              {!this.props.add && this.state.accounts && <><h6>Partager ce profil avec un autre compte : </h6>
                <Row>
                  <Col sm='12' md='5'>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'center' }}>
                      <Form.Group style={{ marginBottom: '0' }}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <OverlayTrigger overlay={<Tooltip>Demandez le #TAG du compte que vous souhaitez ajouter à son propriétaire.<br />Il s'agit d'un code à 6 caractères (lettres et chiffres) visible depuis le menu principal de l'application.<br />Si vous éprouvez des difficultés, contactez le support.</Tooltip>}>
                                <span className='text-info' style={{ cursor: "help", float: "right" }}><FontAwesomeIcon icon={['fas', 'question-circle']} />&nbsp;<FontAwesomeIcon className='text-dark' icon={['fas', 'hashtag']} /></span>
                              </OverlayTrigger>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            placeholder="Tag"
                            maxLength="7"
                            id='add_account_tag'
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <OverlayTrigger overlay={<Tooltip>Ajouter ce compte</Tooltip>}>
                                <span className='text-success' style={{ cursor: "pointer", float: "right" }} onClick={this.linkAccountTag}><FontAwesomeIcon icon={['fas', 'user-plus']} /></span>
                              </OverlayTrigger>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col sm='12' md='2' style={{ textAlign: 'center' }}>
                    <span>- ou -</span>
                  </Col>
                  <Col sm='12' md='5'>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'center' }}>
                      <Form.Group style={{ marginBottom: '0' }}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <OverlayTrigger overlay={<Tooltip>Si l'adresse mail saisie ne correspond à aucun compte, nous enverrons un mail d'invitation.<br />Si vous éprouvez des difficultés, contactez le support.</Tooltip>}>
                                <span className='text-info' style={{ cursor: "help", float: "right" }}><FontAwesomeIcon icon={['fas', 'question-circle']} />&nbsp;<FontAwesomeIcon className='text-dark' icon={['fas', 'at']} /></span>
                              </OverlayTrigger>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            placeholder="Adresse mail"
                            id='add_account_email'
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <OverlayTrigger overlay={<Tooltip>Ajouter ce compte</Tooltip>}>
                                <span className='text-success' style={{ cursor: "pointer", float: "right" }} onClick={this.linkAccountEmail}><FontAwesomeIcon icon={['fas', 'user-plus']} /></span>
                              </OverlayTrigger>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </Col>
                </Row></>}
            </Col>
          </Row>}
        </Modal.Body>
        <Modal.Footer>
          {!this.props.add &&
            <Button variant="danger" onClick={this.deleteGroup}
              disabled={disabled}>
              Supprimer
            </Button>
          }
          <Button variant="secondary" onClick={this.props.handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={this.saveGroup}
            disabled={disabled}>
            {this.props.add
              ? 'Enregistrer le groupe'
              : 'Enregistrer les modifications'}
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}
GroupEditModal.propTypes = {
  saveGroup: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  add: PropTypes.bool,
  group: PropTypes.object,
  availableGroups: PropTypes.array,
  account: PropTypes.object,
  token: PropTypes.string,
  email: PropTypes.string,
};
