import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

import KidEditModal from 'lib/kidEditModal';
import GroupEditModal from 'lib/groupEditModal';
import { GroupTypeIcon } from 'lib/utils';


import Billy from 'assets/Billy.png';
import Olivia from 'assets/Olivia.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class KidList extends Component {
  state = {
    showModalKid: false,
    showModalGroup: false,
    adding: true,
    availableGroups: []
  };

  availableGroups = (groups) => {
    var extract = []
    if (Array.isArray(groups) && groups.length > 0) {
      for (var i = 0; i < groups.length; i++) {
        extract.push(groups[i])
        extract = extract.concat(this.availableGroups(groups[i].children))
      }
    }
    return extract
  }

  openModalGroup = (adding, group) => {
    this.setState(
      produce(draft => {
        draft.showModalGroup = true;
        draft.adding = adding;
        draft.editingGroup = group;
      }),
    );
    this.props.setEditingGroup(group);
  };

  openModal = (adding, kid) => {
    console.log(kid)
    this.setState(
      produce(draft => {
        draft.showModalKid = true;
        draft.adding = adding;
        draft.editingKid = kid;
      }),
    );
    this.props.setEditingKid(kid);
  };

  closeModal = () => {
    this.setState(
      produce(draft => {
        draft.showModalGroup = false;
        draft.showModalKid = false;
      }),
    );
  };

  saveKid = kid => {
    this.props.saveKid(kid, this.state.adding);
    this.closeModal();
  };

  saveGroup = group => {
    this.props.saveGroup(group, this.state.adding);
    this.closeModal();
  };

  onKidClick = async kid => {
    if (!kid) return;
    this.openModal(false, kid);
  };

  onGroupClick = async group => {
    if (!group) return;
    this.openModalGroup(false, group);
  };

  renderKid(kid, index) {
    var avatar
    switch (kid.personnal_info.avatar) {
      case 'Billy': avatar = <img alt='avatar-billy' src={Billy} width="32px" />; break
      case 'Olivia': avatar = <img alt='avatar-olivia' src={Olivia} width="32px" />; break
      default: avatar = <img alt='avatar-billy' src={Billy} width="32px" />; break
    }
    return (
      <ListGroup.Item
        key={index}
        type="button"
        action
        style={{ cursor: 'pointer', border: 'none', borderRadius: '5px', padding: '.1rem .2rem', marginBottom: '2px' }}
        onClick={() => this.onKidClick(kid)}
      >
        {avatar}
        <span style={{ textAlign: "center" }}>{kid.name}</span>
      </ListGroup.Item>
    );
  }

  renderGroup(group, index) {
    if(!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email)) {
      return (
        <div
          key={index}
          type="div"
          style={{ marginBottom: '5px' }}

        >
          {GroupTypeIcon(group.type)}&nbsp;
          <span style={{ cursor: 'pointer', textAlign: "center", fontWeight: "bold" }} onClick={() => this.onGroupClick(group)} >{group.name} [{group.children.reduce((a, b) => a + (b && b.max_students ? b.max_students : 0), 0)
            + group.owned_students.length}/{group.max_students}]</span>
          <div style={{ marginLeft: '10px', borderLeft: '2px dashed #d6eaff', paddingLeft: '10px' }}
          >{this.renderList(group.children, group.owned_students, group)}</div>
        </div>
      );
    } else {
      return (
        <div
          key={index}
          type="div"
          style={{ marginBottom: '5px' }}
  
        >
          {GroupTypeIcon(group.type)}&nbsp;
          <span style={{ cursor: 'pointer', textAlign: "center", fontWeight: "bold" }} >{group.name} </span>
          <div style={{ marginLeft: '10px', borderLeft: '2px dashed #d6eaff', paddingLeft: '10px' }}
          >{this.renderList(group.children, group.owned_students, group)}</div>
        </div>
      );

    }
  }

  renderList(groups, kids, current_group = null) {
    var count_students = 0
    if (current_group) {
      count_students = current_group.children.reduce((a, b) => a + (b && b.max_students ? b.max_students : 0), 0)
        + current_group.owned_students.length
    }
    else {
      count_students = groups.reduce((a, b) => a + (b && b.max_students ? b.max_students : 0), 0)
        + kids.length
    }
    var max_students = current_group ? current_group.max_students : this.props.kidLimit
    return (
      <div className="kidList">
        <div style={{ paddingTop: '0.5em', width: '100%', marginLeft: 0 }}>
          {groups.map(this.renderGroup.bind(this))}
          {kids.map(this.renderKid.bind(this))}
        </div>
        {!(count_students >= max_students) && this.props.account.type === 'TOWNHALL' && !['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) &&
          <Button
            variant="info"
            style={{ marginRight: '2px' }}
            size="sm"
            onClick={() => this.openModalGroup(true, { parent_id: current_group ? current_group.id : null, type: 'SCHOOL', max_students: 1 })}
          >
            Nouvelle école
            </Button>
        }
        {!(count_students >= max_students) && this.props.account.type === 'SCHOOL' && !['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) &&
          <Button
            variant="info"
            style={{ marginRight: '2px' }}
            size="sm"
            onClick={() => this.openModalGroup(true, { parent_id: current_group ? current_group.id : null, type: 'CLASS', max_students: 1 })}
          >
            Nouvelle classe
            </Button>
        }
        {!(count_students >= max_students) && ['TEACHER', 'ADMIN'].includes(this.props.account.type) && !['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) &&
          <Button
            variant="info"
            style={{ marginRight: '2px' }}
            size="sm"
            onClick={() => this.openModalGroup(true, { parent_id: current_group ? current_group.id : null, type: 'GROUP', max_students: 1 })}
          >
            Nouveau groupe
            </Button>
        }
        {!(count_students >= max_students) && !['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) &&
          <Button
            variant="info"
            style={{ marginRight: '2px' }}
            size="sm"
            onClick={() => this.openModal(true, { owner_group_id: current_group ? current_group.id : null })}
          >
            Nouveau profil
            </Button>
        }
      </div>
    );
  }

  render() {
    return <>
      {this.renderList(this.props.groups, this.props.kids)}
      {!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) && <KidEditModal
        show={this.state.showModalKid}
        saveKid={this.saveKid}
        handleClose={this.closeModal}
        add={this.state.adding}
        kid={this.state.editingKid}
        availableGroups={this.availableGroups(this.props.groups)}
        token={this.props.token}
        account={this.props.account}
        email={this.props.email}
      />}
      {!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) && <GroupEditModal
        show={this.state.showModalGroup}
        saveGroup={this.saveGroup}
        handleClose={this.closeModal}
        add={this.state.adding}
        availableGroups={this.availableGroups(this.props.groups)}
        group={this.state.editingGroup}
        token={this.props.token}
        account={this.props.account}
        email={this.props.email}
      />}
      
      {['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) &&
          <div
            style={{ margin: '15px', backgroundColor: 'rgba(188,188,188,.3)', padding: '20px', borderRadius:'15px'}}
          >
            <FontAwesomeIcon icon='info' color="blue" style={{marginRight: '20px'}} />Pour ajouter un profil élève, connectez vous une première fois à Graphonémo avec son compte, il sera ensuite disponible ici.
            </div>
        }
    </>
  }
}

KidList.propTypes = {
  saveKid: PropTypes.func,
  saveGroup: PropTypes.func,
  setEditingKid: PropTypes.func,
  setEditingGroup: PropTypes.func,
  kids: PropTypes.array,
  groups: PropTypes.array,
  token: PropTypes.string,
  account: PropTypes.object,
  email: PropTypes.string
};

KidList.defaultProps = {
  kidLimit: 1,
};
