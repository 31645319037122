import React, { Component } from 'react';
import produce from 'immer';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import logo from 'assets/logo.png';

// error
// 1 : empty field
// 2 : mail incorrect
// 3 : mail existant
// 4 : password mismatch
class Forget extends Component {
  state = {
    email: '',
    done: false
  };

  back = () => {
    this.props.history.push('/login');
  };

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };
  onSubmit = async event => {
    event.preventDefault();
    const response = await API.post(ENDPOINTS.forget, null, {
      email: this.state.email
    });
    if (response) {
      this.setState(
        produce(draft => {
          draft.done = true;
        }),
      );
    }
  };

  render() {
    let content;
    if (this.state.done) {
      content = <div>Demande de réinitialisation effectuée. Si l'adresse saisie correspond à
      un compte Magik Eduk vous recevrez un email vous permettant de réinitialiser
      votre mot de passe dans quelques instants.</div>
    } else {
      content = <Form noValidate onSubmit={event => this.onSubmit(event)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Adresse email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Adresse email"
            value={this.state.email}
            onChange={this.onChange('email')}
          />
        </Form.Group>
        <ButtonToolbar>
          <Button variant="primary" type="submit" block>
            Réinitialiser mon mot de passe
        </Button>
        </ButtonToolbar>
      </Form>
    }
    return (
      <Row className="justify-content-md-center">
        <Col md={5}>
          <img alt="" src={logo} className="logoStart" />
          <Card>
            <Card.Header>Mot de passe oublié</Card.Header>
            <Card.Body>
              {content}
              <Button variant="link" onClick={() => this.back()}>Retour à l'écran de connexion</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Forget);
