import React, { Component } from 'react';
import produce from 'immer';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import QRCode from 'qrcode.react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { AccountTypeIcon, AccountPersonnalInformations, AccountDateFormat } from 'lib/utils';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';



const DatePickerInput = ({ value, onClick, onChange }) => {
  return <Form.Control
    type="input"
    placeholder="Date"
    value={value}
    onClick={onClick}
    onChange={onChange}
  />
}
class AccountEdit extends Component {
  state = {
    account: null
  };

  componentDidMount() {
    if (this.props.match.params.tag === "new") {
      this.setState(
        produce(draft => {
          draft.account = {
            id: "new",
            type: "PRIVATE",
            creation_date: new Date(),
            connexions: [],
            students: [],
            licenses: [],
            comments: []
          }
        })
      );
    } else {
      API.get(ENDPOINTS.account, this.props.token, {
        tag: this.props.match.params.tag,
      }).then(response => {
        if (response && response.code === 'OK') {
          this.setState(
            produce(draft => {
              draft.account = response.account
            }),
          );
        } else {
          this.props.history.push('/home/admin/');
        }
      }).catch(() => {
        this.props.history.push('/home/admin/');
      })
    }
  }

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft.account[field] = value;
      }),
    );
  };
  onChangeConnexion = (i, field) => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft.account.connexions[i][field] = value;
      }),
    );
  };
  onChangeLicense = (i, field) => event => {
    const value = field === 'expiration' ? event : event.target.value;
    this.setState(
      produce(draft => {
        draft.account.licenses[i][field] = value;
      }),
    );
  };
  onChangeDevice = (i, j, field) => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft.account.licenses[i].devices[j][field] = value;
      }),
    );
  };
  onChangeStudent = (i, field) => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft.account.students[i][field] = value;
      }),
    );
  };
  onChangeComment = (i, field) => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft.account.comments[i][field] = value;
      }),
    );
  };

  onSubmit = async evt => {
    evt.preventDefault();
    var account = {
      id: this.state.account.id,
      ingredients: this.state.account.ingredients,
      type: this.state.account.type,
      max_students: this.state.account.max_students,
      connexions: this.state.account.connexions,
      licenses: this.state.account.licenses,
      comments: this.state.account.comments,
      students: []
    }
    for (var i = 0; i < this.state.account.students.length; i++) {
      account.students.push({ id: this.state.account.students[i].id, name: this.state.account.students[i].name })
    }
    const response = await API.post(ENDPOINTS.account, this.props.token, account);
    account.id = response.id
    account.tag = response.tag
    await API.post(ENDPOINTS.accountConnexions, this.props.token, account);
    await API.post(ENDPOINTS.accountLicenses, this.props.token, account);
    await API.post(ENDPOINTS.accountStudents, this.props.token, account);
    await API.post(ENDPOINTS.accountComments, this.props.token, account);
    this.props.history.push('/home/admin/account/' + response.tag);
    document.location.reload()
  };

  render() {
    var account = this.state.account
    if (account) console.log(account.licenses)
    return (
      <Row className="justify-content-md-center homePadding">
        <Col md={12}>
          <Card>
            <Card.Header>{AccountTypeIcon(account ? account.type : '')} <b>#{this.props.match.params.tag}</b></Card.Header>
            <Card.Body>
              {!account && <p>Veuillez patientez pendant le chargement du compte <b>#{this.props.match.params.tag}</b></p>}
              <Form noValidate onSubmit={this.onSubmit}>
                {account && <>
                  <h5>Détails du compte</h5>
                  <Row>
                    <Col>
                      {AccountPersonnalInformations(account)}
                    </Col>
                    <Col style={{ textAlign: "right", fontStyle: "italic" }}>
                      <p>{AccountDateFormat(account.creation_date, true)}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12' md='6' lg='4'>
                      <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select"
                          value={account.type}
                          custom
                          onChange={this.onChange('type')}
                        >
                          <option value='PRIVATE'>Compte Personnel</option>
                          <option value='TEACHER'>Compte Enseignant</option>
                          <option value='SCHOOL'>Compte Ecole</option>
                          <option value='TOWNHALL'>Compte Mairie</option>
                          <option value='ADMIN'>Compte Administrateur</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col sm='12' md='6' lg='4'>
                      <Form.Group >
                        <Form.Label>Champidors</Form.Label>
                        <Form.Control type='number'
                          value={account.ingredients}
                          onChange={this.onChange('ingredients')} />
                      </Form.Group>
                    </Col>

                    <Col sm='12' md='6' lg='4'>
                      <Form.Group >
                        <Form.Label>Nombre d'élèves maximum</Form.Label>
                        <Form.Control type='number'
                          value={account.max_students}
                          onChange={this.onChange('max_students')} />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
                }
                {account && <>
                  <hr />
                  <h5>Identifiants de connexion ({account.connexions.length}):</h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Identifiant</th>
                        <th>Mot de passe</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.connexions.map((connexion, i) => {
                        var resetLink
                        if (connexion.data) resetLink = <span>{connexion.data.resetLink ? <Button variant="link" href={"/reset/" + connexion.data.resetLink}><FontAwesomeIcon icon={['fas', 'globe']} /> Accéder au lien de réinitialisation</Button> : <Button variant="link" onClick={() => this.resetPassword(connexion.identifier)}><FontAwesomeIcon icon={['fas', 'paper-plane']} /> Envoyer un lien de réinitialisation</Button>}</span>
                        return <tr key={i}><td><Form.Control type='input'
                          value={connexion.identifier}
                          onChange={this.onChangeConnexion(i, 'identifier')} /></td><td>{resetLink}</td><td><FontAwesomeIcon className='text-danger' onClick={() => {
                            if (window.confirm('Êtes-vous sûr ? L\'identifiant ' + connexion.identifier + ' sera définitivement supprimé.')) this.setState(
                              produce(draft => {
                                draft.account.connexions.splice(i, 1);
                              })
                            )
                          }} style={{ cursor: "pointer" }} icon={['fas', 'trash-alt']} /></td></tr>
                      })

                      }
                      <tr>
                        <td colSpan={3}><Button variant="success" type="button" onClick={() => this.setState(
                          produce(draft => {
                            draft.account.connexions.push({ id: 'new', identifier: '' });
                          })
                        )} block>
                          Ajouter un identifiant
                        </Button></td>
                      </tr>
                    </tbody>
                  </Table>
                </>
                }

                {account && <>
                  <hr />
                  <h5>Licenses ({account.licenses.length}):</h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Clé</th>
                        <th>Type</th>
                        <th>Date d'expiration</th>
                        <th>Limite appareils</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.licenses.map((license, i) => {
                        return <><tr key={i}>
                          <td className="text-center">{license.license_key && <QRCode value={license.license_key} />}<br />{license.license_key}</td>
                          <td>
                            <Form.Control as="select"
                              value={license.type}
                              custom
                              onChange={this.onChangeLicense(i, 'type')}
                            >
                              <option value='PERSO'>PERSO</option>
                              <option value='PRO'>PRO</option>
                              <option value='PRO_FAMILLE'>PRO_FAMILLE</option>
                            </Form.Control></td>
                          <td>
                            <DatePicker
                              selected={moment(license.expiration).toDate()}
                              onChange={this.onChangeLicense(i, 'expiration')}
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              customInput={<DatePickerInput onChange={this.onChangeLicense(i, 'expiration')} />}
                            /></td>
                          <td>
                            <Form.Control type='number'
                              value={license.max_devices}
                              onChange={this.onChangeLicense(i, 'max_devices')} /></td>
                          <td><FontAwesomeIcon className='text-danger' onClick={() => {
                            if (window.confirm('Êtes-vous sûr ? La licence ' + license.license_key + ' sera définitivement supprimée.')) this.setState(
                              produce(draft => {
                                draft.account.licenses.splice(i, 1);
                              })
                            )
                          }} style={{ cursor: "pointer" }} icon={['fas', 'trash-alt']} /></td>
                        </tr>
                          {license.devices && license.devices.map((device, j) =>
                            <tr key={j}>
                              <td colSpan={5}>&nbsp;&nbsp;&nbsp;&nbsp; - Appareil {j + 1} :&nbsp;
                                <Form.Control type='text'
                                  value={device.name ? device.name : ''}
                                  onChange={this.onChangeDevice(i, j, 'name')}
                                  style={{ display: 'inline-block', width: '300px' }} />&nbsp;<FontAwesomeIcon className='text-danger' onClick={() => {
                                    if (window.confirm('Êtes-vous sûr ? L\'appareil ' + device.name + ' sera définitivement supprimé.')) this.setState(
                                      produce(draft => {
                                        draft.account.licenses[i].devices.splice(j, 1);
                                      })
                                    )
                                  }} style={{ cursor: "pointer" }} icon={['fas', 'trash-alt']} /></td>
                            </tr>

                          )}
                        </>
                      })

                      }
                      <tr>
                        <td colSpan={5}><Button variant="success" type="button" onClick={() => this.setState(
                          produce(draft => {
                            draft.account.licenses.push({ id: 'new', type: 'PERSO', expiration: moment().toDate() });
                          })
                        )} block>
                          Ajouter une licence
                        </Button></td>
                      </tr>
                    </tbody>
                  </Table>
                </>
                }
                {account && <>
                  <hr />
                  <h5>Profils ({account.students.length}/{account.max_students}):</h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.students.map((student, i) => {
                        return <tr key={i}><td><Form.Control type='input'
                          value={student.name}
                          onChange={this.onChangeStudent(i, 'name')} /></td><td><FontAwesomeIcon className='text-danger' onClick={() => {
                            if (window.confirm('Êtes-vous sûr ? Le profil' + student.name + ' sera définitivement supprimé.')) this.setState(
                              produce(draft => {
                                draft.account.students.splice(i, 1);
                              })
                            )
                          }} style={{ cursor: "pointer" }} icon={['fas', 'trash-alt']} /></td></tr>
                      })
                      }
                      <tr>
                        <td colSpan={2}><Button variant="success" type="button" onClick={() => this.setState(
                          produce(draft => {
                            draft.account.students.push({ id: 'new', name: '' });
                          })
                        )} block>
                          Nouveau profil
                        </Button></td>
                      </tr>
                    </tbody>
                  </Table>
                </>
                }
                {account && <>
                  <hr />
                  <h5>Commentaires internes :</h5>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Auteur</th>
                        <th>Commentaire</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {account.comments.map((comment, i) => {
                        return <tr key={i}><td>{AccountDateFormat(comment.date)}</td><td>{comment.author ? comment.author.personnal_info.name : ''}</td><td><Form.Control as='textarea'
                          value={comment.comment}
                          rows="3"
                          onChange={this.onChangeComment(i, 'comment')} /></td><td><FontAwesomeIcon className='text-danger' onClick={() => {
                            if (window.confirm('Êtes-vous sûr ? Ce commentaire sera définitivement supprimé.')) this.setState(
                              produce(draft => {
                                draft.account.comments.splice(i, 1);
                              })
                            )
                          }} style={{ cursor: "pointer" }} icon={['fas', 'trash-alt']} /></td></tr>
                      })
                      }
                      <tr>
                        <td colSpan={4}><Button variant="success" type="button" onClick={() => this.setState(
                          produce(draft => {
                            draft.account.comments.push({ id: 'new', comment: '' });
                          })
                        )} block>
                          Ajouter un commentaire
                        </Button></td>
                      </tr>
                    </tbody>
                  </Table>
                  <ButtonToolbar>
                    <Button variant="primary" type="submit" block>
                      Enregistrer
                    </Button>
                  </ButtonToolbar>
                </>
                }
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row >
    );
  }
}

export default withRouter(AccountEdit);
