import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import ChangePassword from './changePassword';
import PersonalInfo from './personalInfo';

export default class Profile extends Component {
  render() {
    return (
      <Row className="homePadding">
        <Col sm>
          <Card>
            <Card.Header>Mon compte : <b>#{this.props.account.tag}</b></Card.Header>
            {['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) ? 
            <Card.Body>
              <ul>
                <li><b>UAI : </b>{this.props.account?.personnal_info?.GAR?.UAI}</li>
                <li><b>IDO : </b>{this.props.account?.personnal_info?.GAR?.IDO}</li>
                <li><b>PRO : </b>{this.props.account?.personnal_info?.GAR?.PRO}</li>
                <li><b>DIV : </b>{this.props.account?.personnal_info?.GAR?.DIV}</li>
                <li><b>DIV_APP : </b>{this.props.account?.personnal_info?.GAR?.DIV_APP}</li>
                <li><b>GRO : </b>{this.props.account?.personnal_info?.GAR?.GRO}</li>
                <li><b>CIV : </b>{this.props.account?.personnal_info?.GAR?.CIV}</li>
                <li><b>NOM : </b>{this.props.account?.personnal_info?.GAR?.NOM}</li>
                <li><b>PRE : </b>{this.props.account?.personnal_info?.GAR?.PRE}</li>
              </ul>
            </Card.Body> : <Card.Body>
              <Card.Title>Changer mon mot de passe</Card.Title>
              <ChangePassword token={this.props.token} account={this.props.account} />
            </Card.Body>}
          </Card>
        </Col>
        
        {!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) && <Col sm>
          <Card>
            <Card.Header>Mes informations personnelles</Card.Header>
            <PersonalInfo token={this.props.token} account={this.props.account} />
          </Card>
        </Col>}
      </Row>
    );
  }
}

Profile.propTypes = {
  token: PropTypes.string,
  email: PropTypes.string,
  account: PropTypes.object
};
