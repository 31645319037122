import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import produce from 'immer';
import { withCookies, Cookies } from 'react-cookie';
import CookieConsent from 'react-cookie-consent';
import moment from 'moment';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import Login from 'auth/login';
import Register from 'auth/register';
import Forget from 'auth/forget';
import Reset from 'auth/reset';

import QRCode from 'QRCode';

import Home from 'account/home';
import CGU from 'cgu';
import PDC from 'pdc';

// eslint-disable-next-line no-undef

class App extends Component {
  state = {
    token: null,
    account: null,
    email: null,
    license: null
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = { token: cookies.get('mge-token') || null, account: cookies.get('mge-account') || null, email: cookies.get('mge-email') || null, license: cookies.get('mge-license') || null };
  }

  setToken = ({ token, account, email }) => {
    this.setState(
      produce(draft => {
        draft.token = token;
        draft.account = account;
        draft.email = email;
      }),
    );
    this.props.cookies.set('mge-token', token, { path: '/' });
    this.props.cookies.set('mge-account', account, { path: '/' });
    this.props.cookies.set('mge-email', email, { path: '/' });
  };

  logout = () => {
    this.props.cookies.remove('mge-token', { path: '/' });
    this.props.cookies.remove('mge-account', { path: '/' });
    this.props.cookies.remove('mge-email', { path: '/' });
    this.props.cookies.remove('mge-license', { path: '/' });
    this.setState(
      produce(draft => {
        draft.token = null;
        draft.account = null;
        draft.license = null;
      }),
    );
  };

  componentDidMount() {
    API.get(ENDPOINTS.status).then(response => {
      console.log(response);
    });
  }

  render() {
    return (
      <Router>
        <Container>
          <Switch>
            {!this.state.token && (
              <Route
                path="/login"
                render={() => <Login setToken={this.setToken} />}
              />
            )}
            <Route
              path="/qr"
              render={() => <QRCode />}
            />
            {!this.state.token && (
              <Route
                path="/forget"
                render={() => <Forget setToken={this.setToken} />}
              />
            )}
            <Route
              path="/reset/:link(.+)?"
              render={() => <Reset setToken={this.setToken} />}
            />
            <Route
              path="/register/:tag([A-Z0-9]+)/:identifier(.+)?"
              render={() => <Register setToken={this.setToken} />}
            />
            {this.state.token && (
              <Route
                path="/home"
                render={() => (
                  <Home
                    token={this.state.token}
                    account={this.state.account}
                    email={this.state.email}
                    clientId={this.state.id}
                    logout={this.logout}
                    setToken={this.setToken}
                  />
                )}
              />
            )}
            <Route path="/cgu" render={() => <CGU />} />
            <Route path="/pdc" render={() => <PDC />} />
            <Route
              render={() => {
                if (this.state.token) return <Redirect to="/home" />;
                else return <Redirect to="/login" />;
              }}
            />
          </Switch>
          <div
            style={{
              color: '#222',
              position: 'fixed',
              bottom: '1vh',
            }}
          >
            &copy; <a href="/" style={{ color: '#222' }}>MagikEduk</a> {moment().format('YYYY')} -{' '}
            <a href="/cgu" target="_blank">
              Mentions légales & CGU
            </a>{' '}
            -{' '}
            <a href="/pdc" target="_blank">
              Politique de confidentialit&eacute;
            </a>
          </div>
          <CookieConsent
            buttonText="J'ai compris"
            buttonStyle={{
              color: 'white',
              background: '#679436',
              fontSize: '1em',
              borderRadius: 5,
              zIndex: 100000000,
            }}
          >
            Ce site utilise des cookies pour son fonctionnement.
          </CookieConsent>
        </Container>
      </Router>
    );
  }
}

App.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

export default withCookies(App);
