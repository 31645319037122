import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import { useTable, useGroupBy, useExpanded, useSortBy } from 'react-table'
const Styles = styled.div`
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tr:nth-child(even) {background-color: #f6f6f6;}
    tr.expandable:hover,div.openable:hover {
      background-color : #eff;
    }
    
    th,
    td {
      margin: 0;
      padding: 0.2rem;

      
    }
  }
`
const first = (leafValues) => {
  for (var i = 0; i < leafValues.length; i++) { if (typeof leafValues[i] !== 'undefined') return leafValues[i] }
}

const scoreHeader = () => <OverlayTrigger overlay={<Tooltip>Score</Tooltip>}>
  <span className="text-info" style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'star-half-alt']} /></span>
</OverlayTrigger>
const progressionHeader = () => <OverlayTrigger overlay={<Tooltip>Evolution</Tooltip>}>
  <span className="text-info" style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'chart-line']} /></span>
</OverlayTrigger>

const scoreCell = (total, cell) => {
  var details
  try { details = cell.row.original[cell.column.id.split('.')[0]].answers } catch (e) { }
  return (cell.value) ? Details(details, cell.column.id.split('.')[0], cell.value, total) : ""
}
const scoreCell12 = ({ cell }) => scoreCell(12, cell)
const scoreCell6 = ({ cell }) => scoreCell(6, cell)

const scoreAggregated = (total, value) =>
  (value) ? <div style={{ textAlign: 'center', width: '100%' }}>
    <b className={(value / total > 0.9) ? 'text-success' : ((value / total > 0.5) ? 'text-warning' : 'text-danger')} >{value} </b>/ {total}
  </div> : ""
const scoreAggregated12 = ({ value }) => scoreAggregated(12, value)
const scoreAggregated6 = ({ value }) => scoreAggregated(6, value)

const progressionCell = ({ value }) => {
  if (value === 0) return <OverlayTrigger overlay={<Tooltip>+{value}</Tooltip>}>
    <FontAwesomeIcon className="text-warning" style={{ cursor: "help" }} icon={['fas', 'equals']} />
  </OverlayTrigger>

  if (value > 2) return <OverlayTrigger overlay={<Tooltip>+{value}</Tooltip>}>
    <FontAwesomeIcon className="text-success" style={{ cursor: "help" }} icon={['fas', 'angle-double-up']} />
  </OverlayTrigger>

  if (value > 0) return <OverlayTrigger overlay={<Tooltip>+{value}</Tooltip>}>
    <FontAwesomeIcon className="text-success" style={{ cursor: "help" }} icon={['fas', 'angle-up']} />
  </OverlayTrigger>

  if (value < -2) return <OverlayTrigger overlay={<Tooltip>{value}</Tooltip>}>
    <FontAwesomeIcon className="text-danger" style={{ cursor: "help" }} icon={['fas', 'angle-double-down']} />
  </OverlayTrigger>

  if (value < 0) return <OverlayTrigger overlay={<Tooltip>{value}</Tooltip>}>
    <FontAwesomeIcon className="text-danger" style={{ cursor: "help" }} icon={['fas', 'angle-down']} />
  </OverlayTrigger>

  return String("")
}

const progressionAggregated = ({ value }) => {
  if (value === 0) return <FontAwesomeIcon className="text-warning" icon={['fas', 'equals']} />
  if (value > 2) return <FontAwesomeIcon className="text-success" icon={['fas', 'angle-double-up']} />
  if (value > 0) return <FontAwesomeIcon className="text-success" icon={['fas', 'angle-up']} />
  if (value < -2) return <FontAwesomeIcon className="text-danger" icon={['fas', 'angle-double-down']} />
  if (value < 0) return <FontAwesomeIcon className="text-danger" icon={['fas', 'angle-down']} />
  return String("")
}

function Details(details, epreuve, value, total) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var question;
  switch (epreuve) {
    case 'epreuve_1': { question = 'Epreuve 1 (Lettres) : Montre moi la lettre ...'; break }
    case 'epreuve_2': { question = 'Epreuve 2 (Sons) : Montre moi la lettre qui fait le son ...'; break }
    case 'epreuve_3': { question = 'Epreuve 3 (Syllabes) : Montre moi la syllabe ...'; break }
    case 'epreuve_4': { question = 'Epreuve 4 (Digrammes) : Montre moi les lettres pour faire le son ...'; break }
    case 'epreuve_5': { question = 'Epreuve 5 (Mots) : Montre moi le mot ...'; break }
    case 'epreuve_6': { question = 'Epreuve 6 (Trigrammes) : Montre moi les lettres pour faire le son ...'; break }
    case 'epreuve_7': { question = 'Epreuve 7 (Mots complexes) : Montre moi le mot ...'; break }
    case 'epreuve_8': { question = 'Epreuve 8 (Encodage) : Écris ce que te dicte le robot : ...'; break }
    default: { question = 'Erreur' }
  }
  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Cliquez pour ouvrir le détail</Tooltip>}>
        <div style={{ textAlign: 'center', width: '100%', cursor: 'pointer' }} className="openable" onClick={handleShow}>
          <b className={(value / total > 0.9) ? 'text-success' : ((value / total > 0.5) ? 'text-warning' : 'text-danger')} >{value} </b>/ {total}
        </div>
      </OverlayTrigger>

      <Modal show={show} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{question}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {details && details.map((detail, i) =>
            <div style={{ textAlign: 'center' }} key={i}>
              {detail.options && <span>{(i + 1)}.&nbsp;&nbsp;</span>}
              {detail.options && detail.options.map((option, j) => <span style={{ marginRight: '10px', marginLeft: '10px', paddingLeft: '10px', paddingRight: '10px', border: '1px solid #bbb', borderRadius: '5px', backgroundColor: (option === detail.expected ? '#cfc' : (option === detail.answered) ? '#fcc' : '#fff') }} key={j}>{option} {option === detail.expected && option === detail.answered ? <FontAwesomeIcon className="text-success" icon={['fas', 'check']} /> : option === detail.answered ? <FontAwesomeIcon className="text-danger" icon={['fas', 'times']} /> : ''}</span>)}
              {typeof detail.options === 'undefined' && <span style={{ paddingLeft: '20px', paddingRight: '20px', borderBottom: '1px #ddd solid' }}>{(i + 1)}. {detail.answered} {(detail.answered === detail.expected) ? <FontAwesomeIcon className="text-success" icon={['fas', 'check']} /> : <span><FontAwesomeIcon className="text-danger" icon={['fas', 'times']} /> (réponse attendue : {detail.expected})</span>}</span>}
            </div>
          )}
          {!details && <h1>Données introuvables</h1>}
        </Modal.Body>
      </Modal>
    </>
  );
}
function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data,
    initialState: {
      groupBy: ['name'],
      sortBy: [{ id: 'name' }, { id: 'date', desc: true }]
    }
  },
    useGroupBy,
    useSortBy,
    useExpanded)

  // Render the UI for your table
  return (
    <table style={{ minWidth: '1000px', width: "100%" }}{...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th style={{ textAlign: 'center' }} {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} {...(row.isGrouped ? row.getToggleRowExpandedProps({ title: "Cliquez pour  " + (row.isExpanded ? "cacher" : "afficher") + " toutes les évaluations de cet élève", className: 'expandable' }) : undefined)}>
              {row.cells.map(cell => {
                return <td style={{ "whiteSpace": "nowrap" }} {...cell.getCellProps()} >{cell.isGrouped ? (
                  // If it's a grouped cell, add an expander and row count
                  <>
                    <span>
                      {row.isExpanded ? <FontAwesomeIcon className="text-info" icon={['fas', 'minus-square']} /> : <FontAwesomeIcon className="text-info" icon={['fas', 'plus-square']} />}
                    </span>{' '}
                    {cell.render('Cell')} ({row.subRows.length})
                  </>
                ) : cell.isAggregated ? (
                  // If the cell is aggregated, use the Aggregated
                  // renderer for cell
                  cell.render('Aggregated')
                ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                  // Otherwise, just render the regular cell
                  cell.render('Cell')
                )}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}


export default class Evaluations extends Component {

  state = {
    loading: true,
    detailsLoading: false,
    students: [],
    details: null,
  };

  componentDidMount() {
    this.getData();
  }


  getData = () => {
    if (this.props.account.type === 'PRIVATE') {
      this.props.history.push('/home');
      return;
    } else {
      API.get(ENDPOINTS.studentsEval, this.props.token).then(result => {
        if (result.students instanceof Array) {
          this.setState({
            loading: false,
            students: result.students
          });
        }
      })
    }
  };


  render() {
    const columns = [
      {
        Header: 'Éleve',
        accessor: 'name',
      },
      {
        Header: 'Dernière évaluation',
        accessor: 'date',
        aggregate: first,
        Aggregated: ({ value }) => value ? moment(value).fromNow() : "Aucune",
        Cell: ({ value }) => value ? moment(value).format('L LT') : "Aucune"
      },
      {
        Header: 'Lettres',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_1.score',
            aggregate: first,
            Cell: scoreCell12,
            Aggregated: scoreAggregated12
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_1.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
      {
        Header: 'Sons',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_2.score',
            aggregate: first,
            Cell: scoreCell12,
            Aggregated: scoreAggregated12
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_2.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
      {
        Header: 'Syllabes',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_3.score',
            aggregate: first,
            Cell: scoreCell12,
            Aggregated: scoreAggregated12
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_3.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
      {
        Header: 'Digrammes',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_4.score',
            aggregate: first,
            Cell: scoreCell6,
            Aggregated: scoreAggregated6
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_4.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
      {
        Header: 'Mots',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_5.score',
            aggregate: first,
            Cell: scoreCell6,
            Aggregated: scoreAggregated6
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_5.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
      {
        Header: 'Trigrammes',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_6.score',
            aggregate: first,
            Cell: scoreCell6,
            Aggregated: scoreAggregated6
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_6.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
      {
        Header: 'Mots complexes',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_7.score',
            aggregate: first,
            Cell: scoreCell6,
            Aggregated: scoreAggregated6
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_7.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
      {
        Header: 'Encodage',
        columns: [
          {
            Header: scoreHeader,
            accessor: 'epreuve_8.score',
            aggregate: first,
            Cell: scoreCell6,
            Aggregated: scoreAggregated6
          },
          {
            Header: progressionHeader,
            accessor: 'epreuve_8.progression',
            aggregate: first,
            Cell: progressionCell,
            Aggregated: progressionAggregated
          }
        ],
      },
    ]

    return (
      <Row className="homePadding">
        <Col sm>
          <Card>
            <Card.Header>
              Evaluations des élèves
            </Card.Header>
            <Card.Body>
              {this.state.loading && <h6>Chargement...</h6>}
              {!this.state.loading && <Styles style={{ overflowX: 'auto' }}><Table columns={columns} data={this.state.students} evalState={this.state} /></Styles>}
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    );
  }
}

Evaluations.propTypes = {
  token: PropTypes.string,
  history: PropTypes.object,
};
