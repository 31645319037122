import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';
import logo from 'assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Login extends Component {
  state = {
    login: '',
    password: '',
    error: false,
  };

  register = () => {
    this.props.history.push('/register');
  };

  forget = () => {
    this.props.history.push('/forget');
  };

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };

  onSubmit = async event => {
    event.preventDefault();
    const response = await API.post(ENDPOINTS.login, null, {
      email: this.state.login,
      password: this.state.password,
    });
    if (response && response.code === 'OK') {
      await this.setState(
        produce(draft => {
          draft.error = false;
        }),
      );
      this.props.setToken(response);
    } else {
      this.setState(
        produce(draft => {
          draft.error = true;
        }),
      );
    }
  };

  render() {
    return (
      <Row className="justify-content-md-center">
        <Col md={5}>
          <img alt="" src={logo} className="logoStart" />
          <Card>
            <Card.Header>Connexion utilisateur</Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={event => this.onSubmit(event)}>
                {this.state.error && (
                  <Form.Group controlId="formError">
                    <Form.Label style={{ color: 'red' }}>
                      Erreur de connexion, veuillez v&eacute;rifier vos
                      informations
                    </Form.Label>
                  </Form.Group>
                )}
                <p style={{ textAlign: 'center', backgroundColor: '#eef' , padding:'10px', borderRadius:'5px'}}>Accédez à votre compte enseignant directement depuis votre ENT <FontAwesomeIcon icon={['fas', 'chalkboard-teacher']}/> </p>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Adresse email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Adresse email"
                    value={this.state.login}
                    onChange={this.onChange('login')}
                    isInvalid={this.state.error}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Mot de passe"
                    value={this.state.password}
                    onChange={this.onChange('password')}
                    isInvalid={this.state.error}
                  />
                </Form.Group>
                <ButtonToolbar>
                  <Button variant="primary" type="submit" block>
                    Se connecter
                  </Button>
                </ButtonToolbar>
                <Button variant="link" onClick={() => this.forget()}>Mot de passe oublié ?</Button>
                <p style={{ textAlign: 'center' }}>Vous n'avez pas encore de compte ?<br />Téléchargez gratuitement l'application et commencez à jouer dès maintenant</p>
                <p style={{ textAlign: 'center' }}><span style={{ float: 'left' }}><a href="https://play.google.com/store/apps/details?id=com.magikeduk.graphonemo"><img className="alignnone wp-image-1318 size-thumbnail" src="https://www.magikeduk.fr/wp-content/uploads/2019/09/google-store-fr.svg" alt="graphonemo-google-play" width="150" /></a></span>
                  <span style={{ float: 'right' }}><a href="https://apps.apple.com/fr/app/graphonemo/id1457617216"><img className="alignnone wp-image-1317 size-thumbnail" src="https://www.magikeduk.fr/wp-content/uploads/2019/09/apple-store-fr.svg" alt="graphonemo-app-store" width="150" /></a></span></p>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

Login.propTypes = {
  setToken: PropTypes.func,
};

export default withRouter(Login);
