import React, { Component } from 'react';
import produce from 'immer';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import logo from 'assets/logo.png';

// error
// 1 : empty field
// 2 : mail incorrect
// 3 : mail existant
// 4 : password mismatch
class Reset extends Component {
  state = {
    error: 0,
    link: '',
    newpwd: '',
    newpwdCheck: '',
    success: false
  };

  back = () => {
    this.props.history.push('/login');
  };

  componentDidMount = async () => {
    const response = await API.post(ENDPOINTS.checkLink, null, {
      link: this.props.match.params.link,
    });
    if (response && response.success) {
      this.setState(
        produce(draft => {
          draft.link = this.props.match.params.link;
        }),
      );
    } else {
      this.props.history.push('/');
    }
  }

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };
  onSubmit = async event => {
    event.preventDefault();
    if (
      this.state.newpwd === '' ||
      this.state.newpwdCheck === ''
    ) {
      await this.setState(
        produce(draft => {
          draft.success = false;
          draft.error = 1;
        }),
      );
      return;
    }
    if (this.state.newpwd !== this.state.newpwdCheck) {
      await this.setState(
        produce(draft => {
          draft.success = false;
          draft.error = 2;
        }),
      );
      return;
    }
    const update = await API.post(ENDPOINTS.reset, null, {
      link: this.state.link,
      newpwd: this.state.newpwd
    });
    if (update.success) {
      await this.setState(
        produce(draft => {
          draft.error = 0;
          draft.success = true;
        }),
      );
      return;
    }
    await this.setState(
      produce(draft => {
        draft.success = false;
        draft.error = 0;
      }),
    );
  };

  render() {
    let errorMessage = '';
    switch (this.state.error) {
      case 1:
        errorMessage = 'Veuillez saisir un nouveau mot de passe.';
        break;
      case 2:
        errorMessage =
          'Les mots de passe saisis ne sont pas identiques';
        break;
      default:
        break;
    }
    return (
      <Row className="justify-content-md-center">
        <Col md={5}>
          <img alt="" src={logo} className="logoStart" />
          <Card>
            <Card.Header>Modification de mot de passe</Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={event => this.onSubmit(event)}>
                {this.state.error > 0 && (
                  <Form.Group controlId="formError">
                    <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>
                  </Form.Group>
                )}
                {this.state.success && (
                  <Form.Group controlId="formError">
                    <Form.Label style={{ color: 'green' }}>
                      Votre mot de passe a correctement été modifié.
                    </Form.Label>
                  </Form.Group>
                )}
                <Form.Group controlId="formNewPwd">
                  <Form.Label>Nouveau mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Mot de passe"
                    value={this.state.password}
                    onChange={this.onChange('newpwd')}
                    isInvalid={this.state.error === 2 || this.state.error === 3}
                  />
                </Form.Group>
                <Form.Group controlId="formNewPwd2">
                  <Form.Label>
                    R&eacute;p&eacute;tez votre nouveau mot de passe
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Mot de passe"
                    value={this.state.password}
                    onChange={this.onChange('newpwdCheck')}
                    isInvalid={this.state.error === 2 || this.state.error === 3}
                  />
                </Form.Group>
                <ButtonToolbar>
                  {!this.state.success && <Button
                    variant={
                      this.state.success
                        ? 'success'
                        : this.state.error > 0
                          ? 'danger'
                          : 'primary'
                    }
                    type="submit"
                    block
                  >
                    Changer mon mot de passe
                  </Button>}
                </ButtonToolbar>
              </Form>
              <Button variant="link" onClick={() => this.back()}>Retour à l'écran de connexion</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Reset);
