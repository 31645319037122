import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { withCookies } from 'react-cookie';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { get } from 'lib/rest';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';
import QRCode from 'qrcode.react';

class Download extends Component {
  state = {
    paths: null,
    licenses: null,
  };

  componentDidMount() {
    get('/paths.json').then(result => {
      this.setState({ paths: result });
    });
    API.get(ENDPOINTS.licenses, this.props.token)
      .then(result => {
        this.setState({
          licenses: result.licenses
        });
        this.selectLicense(result.licenses[0]?.id)
      });
  }

  selectLicense(id) {
    this.props.cookies.set('mge-license', id, { path: '/' });
  }
  licenseSelected(id) {
    var current = this.props.cookies.get('mge-license')
    if (!current || typeof current === 'undefined') {
      this.props.cookies.set('mge-license', id, { path: '/' })
      return true
    } else return id.toString() === current
  }
  hasLicenseSelected() {
    var current = this.props.cookies.get('mge-license')
    return (current && typeof current !== 'undefined')
  }

  render() {
    return (
      <Row className="homePadding justify-content-md-center">
        <Col md={4}>
          <Card>
            <Card.Header>T&eacute;l&eacute;charger le jeu</Card.Header>
            <Card.Body>
              <Card.Subtitle>
                Version actuelle du jeu :{' '}
                {this.state.paths ? (['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) ? this.state.paths.versiongar : this.state.paths.version) : 'Chargement...'}
              </Card.Subtitle>
              <Card.Text>
                S&eacute;lectionnez la plateforme pour
                t&eacute;l&eacute;charger la derni&egrave;re version du jeu
              </Card.Text>
              {!this.state.paths && <Card.Text>Chargement...</Card.Text>}
              {this.state.paths && (
                <ListGroup>
                  <ListGroup.Item>
                    <a href={(['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) ? this.state.paths.androidgar : this.state.paths.android)} download>
                      <h3>
                        <FontAwesomeIcon icon={['fab', 'google-play']} /> Android
                      </h3>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <a href={(['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) ? this.state.paths.iosgar : this.state.paths.ios)} download>
                      <h3>
                        <FontAwesomeIcon icon={['fab', 'app-store-ios']} /> iOS
                      </h3>
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              )}
              {['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) &&
              <Card.Text>
                Puis scannez le QRCode suivant pour lancer le jeu :
                {!this.state.licenses && "Chargement..."}
                {this.state.licenses && this.state.licenses.map((license, i) => {
                var type = license.type === 'PERSO' ? <span><FontAwesomeIcon icon={['fas', 'user']} /> LICENCE PERSONNELLE</span> : <span><FontAwesomeIcon icon={['fas', 'chalkboard-teacher']} /> LICENCE PROFESSIONNELLE</span>
                return <Row key={i}
                  style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem .5rem', marginBottom: '3px' }}>
                  <Col className="col-auto align-self-center ">
                    <QRCode value={process.env.REACT_APP_SITE_URL+"/qr?t=l&c="+license.license_key} />
                  </Col>
                  <Col>
                    <h5>{type}</h5>
                    <span>Clé d'activation : <b>{license.license_key}</b></span>
                  </Col>
                </Row>
                })}
              </Card.Text>
              }
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>Ou jouer sur votre navigateur</Card.Header>
            <Card.Body>
              <Card.Text>
                S&eacute;lectionnez la licence à utiliser pour jouer en ligne
              </Card.Text>
              {!this.state.licenses && <Card.Text>Chargement...</Card.Text>}
              {this.state.licenses && this.hasLicenseSelected() && <ListGroup.Item>
                {/*<a href={['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) ? '/webgl-gar' : '/webgl'} target='_blank'>*/}
                <a href="/webapp/" target='_blank'>
                  <h3>
                    <FontAwesomeIcon icon={['fas', 'play-circle']} /> Jouer
                      </h3>
                </a>
              </ListGroup.Item>
              }
              {this.state.licenses && this.state.licenses.map((license, i) => {
                var type = license.type === 'PERSO' ? <span><FontAwesomeIcon icon={['fas', 'user']} /> LICENCE PERSONNELLE</span> : <span><FontAwesomeIcon icon={['fas', 'chalkboard-teacher']} /> LICENCE PROFESSIONNELLE</span>
                var expiration = (new Date(license.expiration) < new Date()) ? <span className='text-danger'><FontAwesomeIcon icon={['fas', 'clock']} /> expirée depuis le {moment(license.expiration).format('LLLL')}</span> : <span className='text-success'><FontAwesomeIcon icon={['fas', 'clock']} /> valide jusqu'au {moment(license.expiration).format('LLLL')}</span>
                if(['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email)){
                  expiration = ""
                }
                //}
                return <ListGroup.Item
                  key={i}
                  type="button"
                  action
                  active={this.licenseSelected(license.id)}
                  onClick={() => { this.selectLicense(license.id) }}
                  style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem 1rem' }}
                >
                  <h5>{type}</h5>
                  <h6>{expiration}</h6>
                  <span>Clé d'activation : <b>{license.license_key}</b></span>
                </ListGroup.Item>
              })
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

// <ListGroup.Item>
// <a href={this.state.paths.windows} download>
//   <h3>
//     <FontAwesomeIcon icon={['fab', 'windows']} /> Windows
//   </h3>
// </a>
// </ListGroup.Item>
// <ListGroup.Item>
// <a href={this.state.paths.mac} download>
//   <h3>
//     <FontAwesomeIcon icon={['fab', 'apple']} /> MacOS
//   </h3>
// </a>
// </ListGroup.Item>

Download.propTypes = {
  token: PropTypes.string,
};


export default withCookies(Download)