import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ss_download from 'assets/download.jpg';
import olivia from 'assets/oliviaprofil.png';
import billy from 'assets/billyprofil.png';
import board from 'assets/board.png';
import ss_scanstudent from 'assets/scanstudent.jpg'
import ss_scanlicenceadd from 'assets/scanlicenceadd.jpg'
import ss_dashboardname from 'assets/dashboardname.jpg'
import ss_scanlicencename from 'assets/scanlicencename.jpg'
import { Col, Row } from 'react-bootstrap';
const queryString = require('query-string');

class QRCode extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  componentDidMount() {
  }

  render() {
    const { location } = this.props
    const parsed = queryString.parse(location.search);
    const qr_type = parsed.t;
    const qr_code = parsed.c;

    var title, yt_url
    switch (qr_code) {
      case 'me_ew_1': {
        title = "La forêt des Millemots"
        yt_url = "https://www.youtube.com/embed/fW7RkG6RqCM?autoplay=1"
        break;
      }
      case 'me_ew_2': {
        title = "La mer Syllabis"
        yt_url = "https://www.youtube.com/embed/oCiWwMT-jJg?autoplay=1"
        break;
      }
      case 'me_ew_3': {
        title = "La grotte Audios"
        yt_url = "https://www.youtube.com/embed/utXfEXL8V6k?autoplay=1"
        break;
      }
      case 'me_ew_4': {
        title = "Le volcan Voyello"
        yt_url = "https://www.youtube.com/embed/nt_2VtyCl4w?autoplay=1"
        break;
      }
      case 'me_ew_5': {
        title = "Les nuages Stratos"
        yt_url = "https://www.youtube.com/embed/gm0MkPFpPn0?autoplay=1"
        break;
      }
      case 'me_ew_6': {
        title = "Maléficeland"
        yt_url = "https://www.youtube.com/embed/TncpwsHII5Q?autoplay=1"
        break;
      }
      case 'me_into':
      default: {
        title = "Bienvenue sur Graphonémo"
        yt_url = "https://www.youtube.com/embed/vch4CGqDwZE?autoplay=1"
      }
    }
    if (qr_type === 'l') {
      return (
        <div class="main">
          <div class="tuto__title">Pour ajouter une licence, rendez-vous sur l'application Graphonémo et cliquez sur le bouton "Scan licence"</div>
          <div class="tuto__step">
            <div class="tuto__left">
              <div class="tuto__number">1</div>
              <div class="tuto__text">Téléchargez et installez l'application Graphonémo</div>
            </div>
            <div class="tuto__right">
              <img src={ss_download} alt="Screenshot" />
            </div>
          </div>

          <div class="tuto__step">
            <div class="tuto__left">
              <div class="tuto__number">2</div>
              <div class="tuto__text">Cliquez sur "Scan licence" à l'écran d'identification</div>
            </div>
            <div class="tuto__right">
              <img src={ss_scanlicenceadd} alt="Screenshot" />
            </div>
          </div>

          <div class="tuto__step">
            <div class="tuto__left">
              <div class="tuto__number">3</div>
              <div class="tuto__text">Enregistrez votre nom sur <a href="https://compte.magikeduk.fr/home/profile">le tableau de bord</a> pour le voir apparaître dans l'application</div>
            </div>
            <div class="tuto__right">
              <img src={ss_dashboardname} alt="Screenshot" />
            </div>
          </div>

          <div class="tuto__step">
            <div class="tuto__left">
              <div class="tuto__number">4</div>
              <div class="tuto__text">Votre nom apparaît à présent dans l'application</div>
            </div>
            <div class="tuto__right">
              <img src={ss_scanlicencename} alt="Screenshot" />
            </div>
          </div>

        </div>);
    }

    if (qr_type === 'p') {
      return (
        <div class="main">
          <div class="tuto__title">Pour ajouter un profil, rendez-vous sur l'application Graphonémo et cliquez sur le bouton "Scan profil"</div>
          <div class="tuto__step">
            <div class="tuto__left">
              <div class="tuto__number">1</div>
              <div class="tuto__text">Téléchargez et installez l'application Graphonémo</div>
            </div>
            <div class="tuto__right">
              <img src={ss_download} alt="Screenshot" />
            </div>
          </div>

          <div class="tuto__step">
            <div class="tuto__left">
              <div class="tuto__number">2</div>
              <p class="tuto__text2">( Menu principal -> Autres enfants )</p>
              <div class="tuto__text">Cliquez sur "Scan profil" à l'écran de choix de profil
              </div>
            </div>
            <div class="tuto__right">
              <img src={ss_scanstudent} alt="Screenshot" />
            </div>
          </div>
        </div>);
    }

    if (qr_type === 'v') {
      return (
        <div class="main">
          <Row className="text-center">
            <Col>
              <img src={board} style={{maxWidth: '100%'}} alt="board" />
              <div className="h3 text-white align-center d-block d-md-none" style={{ marginTop: '-65px' }}>{title}</div>
              <div className="h1 text-white align-center d-none d-md-block" style={{ marginTop: '-105px' }}>{title}</div>
            </Col>
          </Row>
          <Row className="tuto__row2">
            <Col className="d-none d-md-block col-1 col-md-2 col-lg-3">
              <img src={olivia} width="100%" alt="olivia" />
            </Col>
            <Col className="col-12 col-md-8 col-lg-6 tuto__video">
              <iframe width="100%" height="400px" src={yt_url} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
            </Col>
            <Col className="d-none d-md-block  col-1 col-md-2 col-lg-3">
              <img src={billy} width="100%" alt="billy" />
            </Col>
          </Row>

          <Row className="tuto__row2 mt-4">
            <Col className="d-block d-md-none col-4">
              <img src={olivia} width="100%" alt="olivia" />
            </Col>
            <Col className="d-block d-md-none col-4">
              <img src={billy} width="100%" alt="billy" />
            </Col>
          </Row>
        </div>);
    }

    return (
      <div
        className={(this.props.home && 'homePadding') || undefined}
        style={{ marginTop: '1em' }}
      >
        <p>Voici la recherche : {location.search}</p>
        <p>Voici le type de code : {qr_type}</p>
        <p>Voici le code : {qr_code}</p>
      </div>
    );
  }
}



export default withRouter(QRCode)