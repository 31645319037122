import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import logo from 'assets/logo.png';

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

// error
// 1 : empty field
// 2 : mail incorrect
// 3 : mail existant
// 4 : password mismatch
class Register extends Component {
  state = {
    step: 0,
    email: '',
    password: '',
    passwordValidate: '',
    error: 0,
    generalError: false,
    acceptedCGU: false,
    tag: null,
  };

  componentDidMount() {
    API.get(ENDPOINTS.checkTag, null, {
      tag: this.props.match.params.tag,
    }).then(response => {
      if (response && response.code === 'OK') {
        if (response.identifier) {
          this.setState(
            produce(draft => {
              draft.generalError = 'Ce compte a déjà été enregistré avec l\'email : ' + response.identifier
            }),
          );
        } else {
          this.setState(
            produce(draft => {
              draft.email = this.props.match.params.identifier;
              draft.tag = this.props.match.params.tag;
            }),
          );
        }
      } else {
        this.setState(
          produce(draft => {
            draft.generalError = 'Compte inconnu'
          }),
        );
      }
    }).catch(() => {
      this.setState(
        produce(draft => {
          draft.generalError = ''
        }),
      );
    })
  }

  checkTag = async () => {
    const { tag } = this.props.match.params;
    const response = await API.get(ENDPOINTS.code, null, {
      code: tag,
    });
    if (response && response.success) {
      this.setState(
        produce(draft => {
          draft.code = tag;
        }),
      );
    } else {
      this.props.history.push('/register');
    }
  };

  login = () => {
    this.props.history.push('/login');
  };

  checkUserInfo = async () => {
    if (!this.state.acceptedCGU) {
      await this.setState(
        produce(draft => {
          draft.error = 5;
        }),
      );
      return false;
    }
    if (
      this.state.email === '' ||
      this.state.password === '' ||
      this.state.passwordValidate === ''
    ) {
      await this.setState(
        produce(draft => {
          draft.error = 1;
        }),
      );
      return false;
    }
    if (!emailRegex.test(this.state.email)) {
      await this.setState(
        produce(draft => {
          draft.error = 2;
        }),
      );
      return false;
    }
    const checkUser = await API.post(ENDPOINTS.exists, null, {
      email: this.state.email,
    });
    if (checkUser.exists) {
      await this.setState(
        produce(draft => {
          draft.error = 3;
        }),
      );
      return false;
    }
    if (this.state.password !== this.state.passwordValidate) {
      await this.setState(
        produce(draft => {
          draft.error = 4;
        }),
      );
      return false;
    }
    return true;
  };

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };

  onSubmit = async evt => {
    evt.preventDefault();
    if (await this.checkUserInfo()) {
      const response = await API.post(ENDPOINTS.register, null, {
        email: this.state.email,
        password: this.state.password,
        tag: this.state.tag,
      });
      if (response && response.code === 'OK') {
        this.props.setToken(response);
        this.props.history.push('/home');
      } else {
        this.setState(
          produce(draft => {
            draft.generalError = 'Veuillez réessayer plus tard.';
          }),
        );
      }
    }
  };

  render() {
    let body = <p>Veuillez patienter, nous recherchons votre compte...</p>
    if (this.state.generalError) {
      body =
        <Alert variant="danger">
          <Alert.Heading>Erreur : </Alert.Heading>
          <p>{this.state.generalError}</p>
        </Alert>
    }
    if (this.state.tag) {
      let errorMessage = '';
      switch (this.state.error) {
        case 1:
          errorMessage = 'Tous les champs sont obligatoires.';
          break;
        case 2:
          errorMessage = 'Veuillez saisir une adresse email valide.';
          break;
        case 3:
          errorMessage = 'Cette adresse email est déjà associée à un compte MagikEduk.';
          break;
        case 4:
          errorMessage = 'Les mots de passes saisis ne sont pas identiques. Veuillez réessayer.';
          break;
        case 5:
          errorMessage = 'Vous devez accepter nos conditions générales d\'utilisation et notre politique de confidentialité.';
          break;
        default:
          break;
      }
      body = <Form noValidate onSubmit={this.onSubmit}>
        {this.state.error > 0 && (
          <Form.Group controlId="formError">
            <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>
          </Form.Group>
        )}
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Adresse email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Adresse email"
            value={this.state.email}
            onChange={this.onChange('email')}
            isInvalid={this.state.error === 2}
          />
          <Form.Text className="text-muted">
            Nous ne partagerons jamais votre adresse email.
        </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Mot de passe"
            value={this.state.password}
            onChange={this.onChange('password')}
            isInvalid={this.state.error === 4}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPasswordValidate">
          <Form.Label>Confirmation du mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirmation du mot de passe"
            value={this.state.passwordValidate}
            onChange={this.onChange('passwordValidate')}
            isInvalid={this.state.error === 4}
          />
        </Form.Group>

        <Form.Group controlId="formBasicCGUValidate">
          <Form.Check
            type="checkbox"
            value={this.state.acceptedCGU}
            onChange={this.onChange('acceptedCGU')}
            isInvalid={this.state.error === 5}
            label="J'accepte les conditions g&eacute;n&eacute;rales d'utilisation et la
          politique de confidentialit&eacute;"
          />
          <Form.Label>
            <ul>
              <li>
                <a href="/cgu" target="_blank">
                  Conditions g&eacute;n&eacute;rales d'utilisation
              </a>
              </li>
              <li>
                <a href="/pdc" target="_blank">
                  Politique de confidentialit&eacute;
              </a>
              </li>
            </ul>
          </Form.Label>
        </Form.Group>

        <ButtonToolbar>
          <Button variant="primary" type="submit" block>
            Créer mon compte
        </Button>
        </ButtonToolbar>
      </Form>
    }
    return (
      <Row className="justify-content-md-center">
        <Col md={5}>
          <img alt="" src={logo} className="logoStart" />
          <Card>
            <Card.Header><FontAwesomeIcon icon={['fas', 'id-card-alt']} /> Créer mon compte</Card.Header>
            <Card.Body>{body}</Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

Register.propTypes = {
  setToken: PropTypes.func
};

export default withRouter(Register);
