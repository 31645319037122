import React, { Component } from 'react';
import Unity, { UnityContent } from "react-unity-webgl";

import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';

import { get } from 'lib/rest';


const unityContent = new UnityContent(
  "/webgl/Build/Build_WebGL.json", "/webgl/Build/UnityLoader.js"
);
export default class Play extends Component {
  state = {
    paths: null,
  };
  componentDidMount() {
    get('/paths.json').then(result => {
      this.setState({ paths: result });
    });
  }
  

  render() {
    return (
      <Row className="homePadding justify-content-md-center">
        <div style={{ width: '960px', height: '600px' }}>
          <Unity unityContent={unityContent} />
        </div>
      </Row>
    );
  }
}

Play.propTypes = {
  token: PropTypes.string,
};
