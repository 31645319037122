import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Kids from './kids';

export default class Account extends Component {
  render() {
    return (
      <Row className="homePadding">
        <Col sm>
          <Kids token={this.props.token} account={this.props.account} email={this.props.email} />
        </Col>
      </Row>
    );
  }
}

Account.propTypes = {
  token: PropTypes.string,
  email: PropTypes.string,
  account: PropTypes.object
};
