import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import {
  API
} from 'lib/rest';
import {
  ENDPOINTS
} from 'config/network';

import KidList from 'lib/kidList';

export default class Kids extends Component {
  state = {
    students: [],
    groups: [],
    editingKid: null,
    editingGroup: null,
    kidLimit: 1,
    error: false,
    loading: true,
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const {
      groups,
      students
    } = await API.get(ENDPOINTS.groups, this.props.token);
    if (groups instanceof Array) {
      await this.setState(
        produce(draft => {
          draft.students = students;
          draft.kidLimit = this.props.account.max_students //? this.props.account.max_students : (this.props.account.type === 'PRIVATE' ? 3 : -1);
          draft.groups = groups;
          draft.groupIds = groups.map(group => group.id);
          draft.loading = false;
        }),
      );

    }
  };

  saveKid = async (student, adding) => {
    await this.setState(
      produce(draft => {
        draft.error = false;
        draft.loading = true;
      }),
    );
    if (adding) {
      const response = await API.post(
        ENDPOINTS.studentAdd,
        this.props.token, {
        name: `${student.name}`,
        personnal_info: student.personnal_info,
        owner_group_id: parseInt(student.owner_group_id),
      });
      if (response.code === 'MAX_CHILDREN_ERROR') {
        await this.setState(
          produce(draft => {
            draft.loading = false
            draft.error = 'Impossible : nombre maximum d\'élèves atteint pour ce groupe'
          }),
        );
      } else {
        document.location.reload();
      }
    } else {
      const response = await API.post(
        ENDPOINTS.studentInfoUpdate,
        this.props.token, {
        id: this.state.editingKid,
        name: `${student.name}`,
        personnal_info: student.personnal_info,
        owner_group_id: parseInt(student.owner_group_id),
      });
      if (response.code === 'MAX_CHILDREN_ERROR') {
        await this.setState(
          produce(draft => {
            draft.loading = false
            draft.error = 'Impossible : nombre maximum d\'élèves atteint pour ce groupe'
          }),
        );
      } else {
        document.location.reload();
      }
    }
  };

  saveGroup = async (group, adding) => {
    await this.setState(
      produce(draft => {
        draft.error = false;
        draft.loading = true;
      }),
    );
    if (adding) {
      const response = await API.post(
        ENDPOINTS.groupAdd,
        this.props.token, {
        name: `${group.name}`,
        type: `${group.type}`,
        max_students: parseInt(group.max_students),
        parent_id: parseInt(group.parent_id),
      }
      );
      if (response.code === 'MAX_CHILDREN_ERROR') {
        await this.setState(
          produce(draft => {
            draft.loading = false
            draft.error = 'Impossible : nombre maximum d\'élèves atteint pour ce groupe'
          }),
        );
      } else {
        document.location.reload();
      }
    } else {
      const response = await API.post(
        ENDPOINTS.groupUpdate,
        this.props.token, {
        id: this.state.editingGroup,
        name: `${group.name}`,
        type: `${group.type}`,
        max_students: parseInt(group.max_students),
        parent_id: parseInt(group.parent_id),
      },
      );
      if (response.code === 'MAX_CHILDREN_ERROR') {
        await this.setState(
          produce(draft => {
            draft.loading = false
            draft.error = 'Impossible : nombre maximum d\'élèves atteint pour ce groupe'
          }),
        );
      } else {
        document.location.reload();
      }
    }
  };

  setEditingKid = student => {
    this.setState(
      produce(draft => {
        draft.editingKid = student.id;
      }),
    );
  };

  setEditingGroup = group => {
    this.setState(
      produce(draft => {
        draft.editingGroup = group.id;
      }),
    );
  };

  render() {
    var title = <span>Mes Profils <b>({this.state.groups.reduce((a, b) => a + (b && this.props.account.id === b.owner_id && b.max_students ? b.max_students : 0), 0) + this.state.students.length}/{this.state.kidLimit})</b></span>
    var error
    if (this.state.error) {
      error =
        <Alert variant='danger'>
          {this.state.error}
        </Alert>
    }
    return (
      <Card>
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          {error}
          {this.state.loading && <Spinner animation="border" role="status">
            <span className="sr-only">Chargement...</span>
          </Spinner>}
          {!this.state.loading &&
            <KidList
              kids={this.state.students}
              groups={this.state.groups}
              saveKid={this.saveKid}
              saveGroup={this.saveGroup}
              setEditingKid={this.setEditingKid}
              setEditingGroup={this.setEditingGroup}
              kidLimit={this.state.kidLimit}
              token={this.props.token}
              account={this.props.account}
              email={this.props.email}
            />}
        </Card.Body>
      </Card>
    );
  }
}

Kids.propTypes = {
  token: PropTypes.string,
  email: PropTypes.string,
  account: PropTypes.object,
};