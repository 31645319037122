import {
  API_URL
} from 'config/network';

export const request = async (url, options, json = true) => {
  return fetch(url, options)
    .then(response => {
      if (response.status === 401) {
        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });

        document.location.reload()
      }
      if (json) return response.json();
      return response;
    })
    .catch(err => {
      console.warn(err);
      return null;
    });
};

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export const get = (
  url, {
    queries = {},
    headers = {
      ...defaultHeaders,
    },
  } = {},
  json = true,
) => {
  let queryUrl = `${url}?`;
  Object.keys(queries).forEach((query, index, arr) => {
    let suffix = '';
    if (index < arr.length) suffix = '&';
    queryUrl = `${queryUrl}${query}=${queries[query]}${suffix}`;
  });

  return request(queryUrl, {
    method: 'GET',
    headers
  }, json);
};

export const post = (
  url, {
    body = null,
    headers = {
      ...defaultHeaders,
    },
  } = {},
  json = true,
) => {
  const options = {
    method: 'POST',
    headers
  };
  if (body !== null && typeof body === 'object')
    options.body = JSON.stringify(body);
  return request(url, options, json);
};

const buildHeaders = token => {
  const headers = {
    ...defaultHeaders,
  };
  if (token) headers.Authorization = `Bearer ${token}`;
  return headers;
};

const buildURL = endpoint => `${API_URL}${endpoint}`;

export const API = {
  get: (endpoint, token = false, queries = {}) => {
    const headers = buildHeaders(token);
    return get(buildURL(endpoint), {
      queries,
      headers,
    });
  },
  post: (endpoint, token = false, body = {}) => {
    const headers = buildHeaders(token);
    return post(buildURL(endpoint), {
      body,
      headers,
    });
  },
};