import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';

import QRCode from 'qrcode.react';

import { API } from 'lib/rest';
import { AccountTypeIcon, EmailCheck } from 'lib/utils';
import { ENDPOINTS, PHONEMES } from 'config/network';

import Billy from 'assets/Billy.png';
import Olivia from 'assets/Olivia.png';


const AccountRow = (account, i, unlink, changeOwner) => {
  let name = "-";
  if (account.personnal_info) {
    try {
      const info = account.personnal_info;
      if (info.name && info.name !== '') {
        name = `${info.name} ${info.surname}`;
      }
    } catch (e) {
      console.error(e);
    }
  }
  var link
  if (unlink) {
    link = <><OverlayTrigger overlay={<Tooltip>Nommer propriétaire du profil</Tooltip>}>
      <span className='text-success' style={{ cursor: "pointer", float: "right", paddingLeft: '8px' }} onClick={() => changeOwner(account)}><FontAwesomeIcon icon={['fas', 'user-shield']} /></span>
    </OverlayTrigger><OverlayTrigger overlay={<Tooltip>Supprimer l'autorisation de ce compte</Tooltip>}>
        <span className='text-danger' style={{ cursor: "pointer", float: "right", paddingLeft: '8px' }} onClick={() => unlink(account)}><FontAwesomeIcon icon={['fas', 'user-times']} /></span>
      </OverlayTrigger></>
  } else {
    link = <OverlayTrigger overlay={<Tooltip>Propriétaire du profil</Tooltip>}>
      <span className='text-warning' style={{ float: "right" }}><FontAwesomeIcon icon={['fas', 'user-shield']} /></span>
    </OverlayTrigger>
  }
  return <tr key={i} style={{ borderBottom: '1px solid #ccc' }}><td>{AccountTypeIcon(account.type)}</td><td><b>#{account.tag}</b></td><td>{name}</td><td>{Array.isArray(account.connexions) && account.connexions.map((connexion, j) => connexion.identifier)}</td><td>{link}</td></tr>
}

export default class KidEditModal extends Component {
  state = {
    id: null,
    error: false,
    personnal_info: '',
    name: '',
  };

  setup = async () => {
    if (this.props.add) {
      this.setState(
        produce(draft => {
          draft.error = false;
          draft.id = null;
          draft.personnal_info = {};
          draft.name = '';
          draft.owner_group_id = this.props.kid.owner_group_id;
        }),
      );
    } else {
      this.setState(
        produce(draft => {
          draft.evals = draft.answers = draft.accounts = null;
          for (var attr in this.props.kid) {
            if (attr === 'date') {
              try {
                this.props.kid[attr] = new Date(this.props.kid[attr])
              } catch (e) {
                this.props.kid[attr] = new Date()
              }
            }
            draft[attr] = this.props.kid[attr]
          }
        })
      );
      const response = await API.get(ENDPOINTS.studentData, this.props.token, {
        student_id: this.props.kid.id,
        evals_limit: 5,
        answers_limit: 5
      })
      if (response && response.code === 'OK') {
        response.student.answers.forEach(answer => {
          if (typeof answer.data.Exo4Answers === 'undefined') answer.data.Exo4Answers = []
          answer.data.exercice1Good = 0;
          answer.data.exercice2Good = 0;
          answer.data.exercice3Good = 0;
          answer.data.exercice4Good = 0;
          answer.data.exercice1Total = Math.min(16, answer.data.Exo1Answers.length);
          answer.data.exercice2Total = Math.min(16, answer.data.Exo2Answers.length);
          answer.data.exercice3Total = Math.min(16, answer.data.Exo3Answers.length);
          answer.data.exercice4Total = Math.min(16, answer.data.Exo4Answers.length);
          answer.data.Exo1Answers.forEach(question => {
            if (question.answered === question.expected) answer.data.exercice1Good++
          })
          answer.data.Exo2Answers.forEach(question => {
            if (question.answered === question.expected) answer.data.exercice2Good++
          })
          answer.data.Exo3Answers.forEach(question => {
            if (JSON.stringify(question.sylpos) === JSON.stringify(question.syllabes)) answer.data.exercice3Good++
          })
          answer.data.Exo4Answers.forEach(question => {
            if (question.answered === question.expected) answer.data.exercice4Good++
          })
        });

        console.log(response.student.owner)
        this.setState(
          produce(draft => {
            draft.evals = response.student.evals
            draft.answers = response.student.answers
            draft.owner = response.student.owner
            draft.owner_group_id = response.student.owner_group_id
            draft.accounts = response.student.accounts
          })
        );
      }
    }
  };

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };

  onChangePI = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft.personnal_info[field] = value;
      }),
    );
  };

  onCheck = field => () => {
    this.setState(
      produce(draft => {
        draft[field] = !this.state[field];
      }),
    );
  };

  onDateChange = date => {
    this.setState(
      produce(draft => {
        draft.date = date;
      }),
    );
  };

  saveKid = (evt) => {
    evt.preventDefault()
    if (!this.state.name || this.state.name.trim() === '') {
      this.setState(
        produce(draft => {
          draft.error = true;
        }),
      );
    } else {
      const {
        personnal_info,
        id,
        name,
        owner_group_id
      } = this.state;

      this.props.saveKid({
        personnal_info,
        id,
        name,
        owner_group_id
      });
    }
  };

  linkAccountTag = async () => {
    const studentResponse = await API.post(
      ENDPOINTS.studentAccountLink,
      this.props.token,
      {
        student_id: this.state.id,
        tag: document.getElementById('add_account_tag').value
      },
    );
    if (studentResponse && studentResponse.code === 'FORBIDDEN') {
      document.location.reload()
    } else if (studentResponse && studentResponse.code === 'OK') {
      this.setup()
    } else {
      alert('Tag inconnu, veuillez vérifier.')
    }
  };

  linkAccountEmail = async () => {
    if (!EmailCheck(document.getElementById('add_account_email').value)) {
      alert('Veuillez saisir un email valide')
    } else {
      const studentResponse = await API.post(
        ENDPOINTS.studentAccountLinkEmail,
        this.props.token,
        {
          student_id: this.state.id,
          email: document.getElementById('add_account_email').value
        },
      );
      if (studentResponse && studentResponse.code === 'FORBIDDEN') {
        document.location.reload()
      } else if (studentResponse && (studentResponse.code === 'OK' || studentResponse.code === 'CREATED')) {
        this.setup()
      } else {
        alert('Erreur, veuillez contacter le support.')
      }
    }
  };

  unlinkAccount = async (account) => {
    const ask = (this.props.account.id === account.id) ? "Attention : Vous êtes sur le point de supprimer votre propre accès à ce profil. Il ne sera donc plus disponible sur votre compte. Êtes-vous sûr de vouloir continuer ?" : "Attention : Vous êtes sur le point de supprimer l'accès du compte #" + account.tag + " à ce profil. Êtes-vous sûr de vouloir continuer ?"
    if (window.confirm(ask)) {
      const studentResponse = await API.post(
        ENDPOINTS.studentAccountUnlink,
        this.props.token,
        {
          student_id: this.state.id,
          account_id: account.id
        },
      );
      if (studentResponse && studentResponse.code === 'DELETED') {
        if (this.props.account.id === account.id) {
          document.location.reload()
        } else {
          this.setup()
        }
      }
    }
  };

  changeOwnerAccount = async (account) => {
    const ask = "Attention : Vous êtes sur le point de changer le propriétaire du profil. Il ne sera donc plus éditable sur votre compte. Êtes-vous sûr de vouloir continuer ?"
    if (window.confirm(ask)) {
      const response = await API.post(
        ENDPOINTS.studentAccountChangeOwner,
        this.props.token,
        {
          student_id: this.state.id,
          account_id: account.id
        },
      );
      if (response && response.code === 'OK') {
        this.setup()
      }
    }
  };

  deleteKid = async () => {
    if (window.confirm("Attention : si vous supprimez ce profil il ne sera plus accessible sur aucun compte et ses données seront définitivement perdue, voulez-vous quand même continuer ?")) {
      const studentResponse = await API.post(
        ENDPOINTS.studentRemove,
        this.props.token,
        {
          id: this.state.id
        },
      );
      if (studentResponse && studentResponse.code === 'DELETED') {
        document.location.reload();
      }
    }
  };

  printShare = () => {
    var dataUrl = document.getElementById('shareQR').toDataURL();
    var windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Partage du profil ' + this.state.name + '</title></head>';
    windowContent += '<body>'
    windowContent += '<div>'
    windowContent += '<img style="display:inline-block; width: 15%;" src="' + dataUrl + '"> '
    windowContent += '<h1 style="display:inline-block; width: 75%;margin-left:3%;">'
    windowContent += 'Scannez ce QRCode dans l\'application Graphonémo pour jouer avec le profil ' + this.state.name
    windowContent += '</h1>'
    windowContent += '</div>'
    windowContent += '</body>';
    windowContent += '</html>';
    var printWin = window.open('', 'PRINT', 'width=800,height=600');
    printWin.document.write(windowContent);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    return true
  }

  render() {
    var errorMessage = 'Le champ prénom est obligatoire';
    var disabled = (!this.props.add);
    if (this.state.owner) {
      if (this.state.owner.id === this.props.account.id || ['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email)) {
        disabled = false
      } else {
        errorMessage = "Vous n'êtes pas le propriétaire de ce profil"
      }
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        onEnter={this.setup}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.add
              ? 'Nouveau profil'
              : ` ${this.state.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.saveKid}>{(this.state.error || (this.state.owner && disabled)) && (
            <Form.Group controlId="formError">
              <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>
            </Form.Group>
          )}
            <Row>
              {!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) && <Col className="col-auto">
                <OverlayTrigger overlay={<Tooltip>Imprimer la fiche de partage</Tooltip>}>

                  <QRCode id='shareQR' style={{ cursor: "pointer" }} onClick={this.printShare} value={process.env.REACT_APP_SITE_URL + "/qr?t=p&c=" + this.state.token} />

                </OverlayTrigger>
              </Col>}
              <Col className="col-auto">
                <Form.Group>
                  <Form.Label>Nom du profil</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Nom du profil"
                    value={this.state.name}
                    onChange={this.onChange('name')}
                    disabled={disabled}
                    isInvalid={this.state.error}
                  />
                </Form.Group>
              </Col>
              <Col>
                Avatar : <br />
                <Form.Group>
                  <Form.Check
                    type="radio"
                    inline
                    custom
                    label="Billy"
                    id="radio-billy"
                    value='Billy'
                    onChange={this.onChangePI('avatar')}
                    checked={this.state.personnal_info.avatar !== 'Olivia'}
                  />
                  <label htmlFor="radio-billy"><img alt='avatar-billy' src={Billy} width="64px" /></label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Form.Check
                    type="radio"
                    inline
                    custom
                    label="Olivia"
                    id='radio-olivia'
                    value='Olivia'
                    onChange={this.onChangePI('avatar')}
                    checked={this.state.personnal_info.avatar === 'Olivia'}
                  />
                  <label htmlFor="radio-olivia"><img alt='avatar-olivia' src={Olivia} width="64px" /></label>

                </Form.Group>
              </Col>
              {this.props.availableGroups && this.props.availableGroups.length > 0 && (
                <Col>
                  <Form.Group>
                    <Form.Label>Déplacer vers le groupe :</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Groupe"
                      value={this.state.owner_group_id ? this.state.owner_group_id : ""}
                      onChange={this.onChange('owner_group_id')}
                      disabled={disabled}
                    >
                      <option value=""> - </option>
                      {this.props.availableGroups.map((group, i) => {
                        if (this.state.id !== group.id) {
                          return <option key={i} value={group.id}>{group.name}</option>
                        }
                        return null
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>)}
            </Row>
          </Form>
          <hr />
          {!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) &&
            <Row>
              <Col sm='12'>
                {!this.props.add && <h6>Comptes ayant accès à ce profil :</h6>}
                {!this.props.add && !this.state.accounts && <>Chargement des comptes autorisés...</>}

                <Table striped bordered hover size="sm">
                  <tbody>
                    {this.state.owner && AccountRow(this.state.owner, 0)}
                    {Array.isArray(this.state.accounts) && this.state.accounts.map((account, i) =>
                      (this.state.owner.id !== account.id ? AccountRow(account, i, this.unlinkAccount, this.changeOwnerAccount) : <tr key={i}></tr>)
                    )}
                  </tbody>
                </Table>
                {!this.props.add && this.state.accounts && <><h6>Partager ce profil avec un autre compte : </h6>
                  <Row>
                    <Col sm='12' md='5'>
                      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'center' }}>
                        <Form.Group style={{ marginBottom: '0' }}>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <OverlayTrigger overlay={<Tooltip>Demandez le #TAG du compte que vous souhaitez ajouter à son propriétaire.<br />Il s'agit d'un code à 6 caractères (lettres et chiffres) visible depuis le menu principal de l'application.<br />Si vous éprouvez des difficultés, contactez le support.</Tooltip>}>
                                  <span className='text-info' style={{ cursor: "help", float: "right" }}><FontAwesomeIcon icon={['fas', 'question-circle']} />&nbsp;<FontAwesomeIcon className='text-dark' icon={['fas', 'hashtag']} /></span>
                                </OverlayTrigger>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              placeholder="Tag"
                              maxLength="7"
                              id='add_account_tag'
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <OverlayTrigger overlay={<Tooltip>Ajouter ce compte</Tooltip>}>
                                  <span className='text-success' style={{ cursor: "pointer", float: "right" }} onClick={this.linkAccountTag}><FontAwesomeIcon icon={['fas', 'user-plus']} /></span>
                                </OverlayTrigger>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col sm='12' md='2' style={{ textAlign: 'center' }}>
                      <span>- ou -</span>
                    </Col>
                    <Col sm='12' md='5'>
                      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'center' }}>
                        <Form.Group style={{ marginBottom: '0' }}>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <OverlayTrigger overlay={<Tooltip>Si l'adresse mail saisie ne correspond à aucun compte, nous enverrons un mail d'invitation.<br />Si vous éprouvez des difficultés, contactez le support.</Tooltip>}>
                                  <span className='text-info' style={{ cursor: "help", float: "right" }}><FontAwesomeIcon icon={['fas', 'question-circle']} />&nbsp;<FontAwesomeIcon className='text-dark' icon={['fas', 'at']} /></span>
                                </OverlayTrigger>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              placeholder="Adresse mail"
                              id='add_account_email'
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <OverlayTrigger overlay={<Tooltip>Ajouter ce compte</Tooltip>}>
                                  <span className='text-success' style={{ cursor: "pointer", float: "right" }} onClick={this.linkAccountEmail}><FontAwesomeIcon icon={['fas', 'user-plus']} /></span>
                                </OverlayTrigger>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row></>}
              </Col>
            </Row>
          }
          <hr />
          <Row>
            <Col sm='12' xl='6' >
              {!this.props.add && <h6>Dernières évaluations :</h6>}
              {!this.props.add && !this.state.evals && <Col>Chargement des dernières évaluations...</Col>}
              {Array.isArray(this.state.evals) && this.state.evals.map((test, i) => {
                if (test.data && Array.isArray(test.data.answers) && test.data.answers.length > 0) return <Row
                  key={test.data.id} style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem 1rem', margin: '5px' }} >
                  <Col sm='12' >
                    <FontAwesomeIcon icon={['fas', 'clipboard-list']} />&nbsp;&nbsp;
                    <OverlayTrigger overlay={<Tooltip>Date de l'évaluation : {test.data.timeStart && moment(test.data.timeStart * 1000).format('LLLL')}</Tooltip>}>
                      <span style={{ cursor: "help", fontStyle: "italic", float: 'right' }}><FontAwesomeIcon icon={['fas', 'history']} /> {moment(test.data.timeStart * 1000).fromNow()} </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Durée de l'évaluation</Tooltip>}>
                      <span style={{ cursor: "help", fontStyle: "italic" }}><FontAwesomeIcon icon={['fas', 'clock']} /> {moment.duration(1000 * (test.data.timeEnd - test.data.timeStart)).humanize()}</span>
                    </OverlayTrigger>
                  </Col>
                  {Array.isArray(test.data.answers) && test.data.answers.map((answer, j) =>
                    <Col className={(test.data.score[j] / answer.length > 0.75) ? 'bg-success' : ((test.data.score[j] / answer.length > 0.45) ? 'bg-warning' : 'bg-danger')} key={j} sm='12' xl='6' style={{ textAlign: 'center' }}>
                      <OverlayTrigger overlay={<Tooltip>{'Épreuve ' + (j + 1)}</Tooltip>}>
                        <span style={{ cursor: 'help' }}><img alt={'Épreuve ' + (j + 1)} width="24px" src={require('assets/icons/epreuve.png')} />&nbsp;{j + 1} </span>
                      </OverlayTrigger>: <b>{test.data.score[j]} / {answer.length}</b>
                    </Col>
                  )}
                </Row>
                else return undefined
              })
              }
            </Col><Col sm='12' xl='6' >
              {!this.props.add && <h6>Derniers exercices :</h6>}
              {!this.props.add && !this.state.answers && <Col>Chargement des derniers exercices...</Col>}
              {Array.isArray(this.state.answers) && this.state.answers.map((answer, i) => {
                if (answer.data && typeof answer.data.phonemeId !== 'undefined') return <Row
                  key={i} style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem 1rem', margin: '5px' }} >
                  <Col sm='12' >
                    <OverlayTrigger overlay={<Tooltip>Phonème : {PHONEMES[answer.data.phonemeId - 1]}</Tooltip>}>
                      <span style={{ cursor: "help", fontStyle: "italic", float: 'left' }}><img alt={PHONEMES[answer.data.phonemeId - 1]} width="32px" src={require('assets/borel/' + answer.data.phonemeId + '.png')} />&nbsp;&nbsp;</span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Date de l'évaluation : {answer.data.timeStart && moment(1000 * answer.data.timeStart).format('LLLL')}</Tooltip>}>
                      <span style={{ cursor: "help", fontStyle: "italic", float: 'right' }}><FontAwesomeIcon icon={['fas', 'history']} /> {moment(1000 * answer.data.timeStart).fromNow()} </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Durée de l'exercice complet</Tooltip>}>
                      <span style={{ cursor: "help", fontStyle: "italic" }}><FontAwesomeIcon icon={['fas', 'clock']} /> {moment.duration(1000 * (answer.data.timeEnd - answer.data.timeStart)).humanize()}</span>
                    </OverlayTrigger>
                  </Col>
                  {
                    Array.isArray(answer.data.Exo1Answers) && answer.data.exercice1Total > 0 &&
                    <Col className={(answer.data.exercice1Good / answer.data.exercice1Total > 0.75) ? 'bg-success' : ((answer.data.exercice1Good / answer.data.exercice1Total > 0.45) ? 'bg-warning' : 'bg-danger')} sm='12' style={{ textAlign: 'center' }}>
                      <OverlayTrigger overlay={<Tooltip>Activité 1</Tooltip>}>
                        <img style={{ cursor: "help" }} alt='Activité 1' width='24px' src={require('assets/icons/Asset_1.png')} />
                      </OverlayTrigger>&nbsp;&nbsp;<b>{answer.data.exercice1Good} / {answer.data.exercice1Total}</b>&nbsp;&nbsp;<OverlayTrigger overlay={<Tooltip>Durée de l'Activité 1 : {moment.duration(1000 * answer.data.exercice1Time).humanize()}</Tooltip>}>
                        <span style={{ cursor: "help", fontStyle: "italic" }}><FontAwesomeIcon icon={['fas', 'clock']} /></span>
                      </OverlayTrigger>
                    </Col>
                  }
                  {
                    Array.isArray(answer.data.Exo2Answers) && answer.data.exercice2Total > 0 &&
                    <Col className={(answer.data.exercice2Good / answer.data.exercice2Total > 0.75) ? 'bg-success' : ((answer.data.exercice2Good / answer.data.exercice2Total > 0.45) ? 'bg-warning' : 'bg-danger')} sm='12' style={{ textAlign: 'center' }}>
                      <OverlayTrigger overlay={<Tooltip>Activité 2</Tooltip>}><img style={{ cursor: "help" }} alt='Activité 2' width='24px' src={require('assets/icons/Asset_2.png')} /></OverlayTrigger>&nbsp;&nbsp;<b>{answer.data.exercice2Good} / {answer.data.exercice2Total}</b>&nbsp;&nbsp;<OverlayTrigger overlay={<Tooltip>Durée de l'Activité 2 : {moment.duration(1000 * answer.data.exercice2Time).humanize()}</Tooltip>}>
                        <span style={{ cursor: "help", fontStyle: "italic" }}><FontAwesomeIcon icon={['fas', 'clock']} /></span>
                      </OverlayTrigger>
                    </Col>
                  }
                  {
                    Array.isArray(answer.data.Exo3Answers) && answer.data.exercice3Total > 0 &&
                    <Col className={(answer.data.exercice3Good / answer.data.exercice3Total > 0.75) ? 'bg-success' : ((answer.data.exercice3Good / answer.data.exercice3Total > 0.45) ? 'bg-warning' : 'bg-danger')} sm='12' style={{ textAlign: 'center' }}>
                      <OverlayTrigger overlay={<Tooltip>Activité 3</Tooltip>}><img style={{ cursor: "help" }} alt='Activité 3' width='24px' src={require('assets/icons/Asset_3.png')} /></OverlayTrigger>&nbsp;&nbsp;<b>{answer.data.exercice3Good} / {answer.data.exercice3Total}</b>&nbsp;&nbsp;<OverlayTrigger overlay={<Tooltip>Durée de l'Activité 3 : {moment.duration(1000 * answer.data.exercice3Time).humanize()}</Tooltip>}>
                        <span style={{ cursor: "help", fontStyle: "italic" }}><FontAwesomeIcon icon={['fas', 'clock']} /></span>
                      </OverlayTrigger>
                    </Col>
                  }
                  {
                    Array.isArray(answer.data.Exo4Answers) && answer.data.exercice4Total > 0 &&
                    <Col className={(answer.data.exercice4Good / answer.data.exercice4Total > 0.75) ? 'bg-success' : ((answer.data.exercice4Good / answer.data.exercice4Total > 0.45) ? 'bg-warning' : 'bg-danger')} sm='12' style={{ textAlign: 'center' }}>
                      <OverlayTrigger overlay={<Tooltip>Activité 4</Tooltip>}><img style={{ cursor: "help" }} alt='Activité 4' width='24px' src={require('assets/icons/Asset_4.png')} /></OverlayTrigger>&nbsp;&nbsp;<b>{answer.data.exercice4Good} / {answer.data.exercice4Total}</b>&nbsp;&nbsp;<OverlayTrigger overlay={<Tooltip>Durée de l'Activité 4 : {moment.duration(1000 * answer.data.exercice4Time).humanize()}</Tooltip>}>
                        <span style={{ cursor: "help", fontStyle: "italic" }}><FontAwesomeIcon icon={['fas', 'clock']} /></span>
                      </OverlayTrigger>
                    </Col>
                  }
                </Row>
                else return undefined
              })
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!this.props.add &&
            <Button variant="danger" onClick={this.deleteKid}>
              Supprimer
            </Button>
          }
          <Button variant="secondary" onClick={this.props.handleClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={this.saveKid}>
            {this.props.add
              ? 'Enregistrer'
              : 'Enregistrer les modifications'}
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}
KidEditModal.propTypes = {
  saveKid: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  add: PropTypes.bool,
  kid: PropTypes.object,
  availableGroups: PropTypes.array,
  account: PropTypes.object,
  token: PropTypes.string,
  email: PropTypes.string,
};
