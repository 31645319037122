import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import QRCode from 'qrcode.react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { API } from 'lib/rest';
import { ENDPOINTS, SITE_URL } from 'config/network';

export default class Licenses extends Component {
  state = {
    loading: true,
    licenses: null,
    plans: null,
    selectedPlan: null,
    showBuyModal: false,
    userMail: null,
    buySuccess: false,
    buyError: false,
    buyWait: false,
    showConfirmUnsubscribe: false
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    API.get(ENDPOINTS.licenses, this.props.token)
      .then(result => {
        this.setState({
          licenses: result.licenses,
          plans: result,
          userMail: this.props.account.current_identifier,
          loading: false,
        });
      });
  };

  showPlanDetails = plan => {
    this.setState({ selectedPlan: plan, showBuyModal: true });
  };
  handleBuyModalClose = () => {
    this.setState({
      selectedPlan: null,
      showBuyModal: false,
      buyWait: false,
      buyError: false,
      buySuccess: false,
    });
  };

  showConfirmUnsubModal = plan => {
    this.setState({ showConfirmUnsubscribe: true });
  };
  handleConfirmUnsubModalClose = () => {
    this.setState({
      showConfirmUnsubscribe: false,
    });
  };
  doUnsub = async () => {
    await API.post(ENDPOINTS.cancelSub, this.props.token);
    this.getData();
    this.handleConfirmUnsubModalClose();
  };

  payCallback = (err, success) => {
    if (err && err !== null) {
      this.setState({ buyWait: false, buySuccess: false, buyError: true });
      return;
    }
    if (success) {
      this.getData();
      this.setState({ buyWait: false, buyError: false, buySuccess: true });
    } else {
      this.setState({ buyWait: true });
    }
  };

  toggleActivation = async device => {
    let promise = Promise.resolve();
    if (device.active === 1) {
      promise = promise.then(() =>
        API.post(ENDPOINTS.activateDevice, this.props.token, {
          deviceId: device.id,
          active: 0,
        }),
      );
    } else {
      promise = promise.then(() =>
        API.post(ENDPOINTS.activateDevice, this.props.token, {
          deviceId: device.id,
          active: 1,
        }),
      );
    }
    promise = promise
      .then(() => API.get(ENDPOINTS.license, this.props.token))
      .then(result => {
        this.setState({ currentLicense: result });
        return null;
      });
    await promise;
  };

  createCode = async () => {
    let promise = Promise.resolve();
    promise = promise.then(() => API.post(ENDPOINTS.codes, this.props.token));
    promise = promise
      .then(() => API.get(ENDPOINTS.codes, this.props.token))
      .then(result => {
        this.setState({
          codes: result.codes,
          pros: result.pros,
        });
      });
    await promise;
  };

  deleteCode = async code => {
    let promise = Promise.resolve();
    promise = promise.then(() =>
      API.post(ENDPOINTS.delCode, this.props.token, {
        codeId: code.id,
      }),
    );
    promise = promise
      .then(() => API.get(ENDPOINTS.codes, this.props.token))
      .then(result => {
        this.setState({
          codes: result.codes,
          pros: result.pros,
        });
      });
    await promise;
  };

  deletePro = async pro => {
    let promise = Promise.resolve();
    promise = promise.then(() =>
      API.post(ENDPOINTS.delPro, this.props.token, {
        proId: pro.id,
      }),
    );
    promise = promise
      .then(() => API.get(ENDPOINTS.codes, this.props.token))
      .then(result => {
        this.setState({
          codes: result.codes,
          pros: result.pros,
        });
      });
    await promise;
  };

  deviceEditing = (license_id, device_id) => {
    var licenses = this.state.licenses
    licenses[license_id].devices[device_id].editingRef = React.createRef();
    licenses[license_id].devices[device_id].originalName = licenses[license_id].devices[device_id].name;
    this.setState({
      licenses
    })
  }

  deviceChange = (license_id, device_id) => event => {
    var licenses = this.state.licenses
    const value = event.target.value;
    licenses[license_id].devices[device_id].name = value
    this.setState({
      licenses
    })
  };
  deviceRename = async (license_id, device_id) => {
    var licenses = this.state.licenses

    const response = await API.post(ENDPOINTS.deviceRename, this.props.token, {
      id: licenses[license_id].devices[device_id].id,
      name: licenses[license_id].devices[device_id].editingRef.current.value
    })
    if (response && response.code === 'OK') {
      licenses[license_id].devices[device_id].name = licenses[license_id].devices[device_id].editingRef.current.value
      delete licenses[license_id].devices[device_id].originalName
      delete licenses[license_id].devices[device_id].editingRef

      this.setState({
        licenses
      })
    }
  }
  deviceCancel = (license_id, device_id) => {
    var licenses = this.state.licenses
    licenses[license_id].devices[device_id].name = licenses[license_id].devices[device_id].originalName
    delete licenses[license_id].devices[device_id].originalName
    delete licenses[license_id].devices[device_id].editingRef
    this.setState({
      licenses
    })
  }
  deviceRemove = async (license_id, device_id) => {
    var licenses = this.state.licenses

    const response = await API.post(ENDPOINTS.deviceRemove, this.props.token, {
      device_id: licenses[license_id].devices[device_id].id,
      license_id : licenses[license_id].id
    })
    if (response && response.code === 'DELETED') {
      licenses[license_id].devices.splice(device_id, 1)
      this.setState({
        licenses
      })
    }
  }

  renderCurrentLicenses() {
    if (!this.state.licenses)
      return <div>Erreur</div>; // impossible
    moment.locale('fr');
    if (this.state.licenses.length === 0) {
      if (this.props.account.type === 'PRIVATE') {
        return <div>
          <h5><FontAwesomeIcon icon={['fas', 'user']} /> LICENCE PERSONNELLE</h5>
          <h6>Veuillez vous connectez en jeu pour activer votre licence</h6>
        </div>
      } else {
        return <div>
          <h5><FontAwesomeIcon icon={['fas', 'chalkboard-teacher']} /> COMPTE PROFESSIONNEL</h5>
          <h6>Vous ne disposez actuellement d'aucune licence Graphonemo</h6>
        </div>
      }
    } else {
      return this.state.licenses.map((license, i) => {
        var type = license.type === 'PERSO' ? <span><FontAwesomeIcon icon={['fas', 'user']} /> LICENCE PERSONNELLE</span> : <span><FontAwesomeIcon icon={['fas', 'chalkboard-teacher']} /> LICENCE PROFESSIONNELLE</span>
        var expiration = (new Date(license.expiration) < new Date()) ? <span className='text-danger'><FontAwesomeIcon icon={['fas', 'clock']} /> expirée depuis le {moment(license.expiration).format('LLLL')}</span> : <span className='text-success'><FontAwesomeIcon icon={['fas', 'clock']} /> valide jusqu'au {moment(license.expiration).format('LLLL')}</span>
        if(['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email)){
          expiration = "";
        }
        var devices;
        //if (license.devices.length > 0) {
        devices = (
          <span className='text-info'>Activée sur {license.devices.length} appareil{license.devices.length > 1 && "s"} (maximum: {license.max_devices}) <br />{license.devices.map((device, j) => {
            var name = (device.editingRef || device.name ? device.name : 'Appareil inconnu')
            return <><span key={j}>&nbsp;&nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={['fas', 'check-square']} />
              &nbsp;{!device.editingRef && name}{device.editingRef && <Form.Control
                type="input"
                placeholder="Nom"
                style={{ display: 'inline-block', width: 'inherit' }}
                ref={device.editingRef}
                onChange={this.deviceChange(i, j)}
                value={name}
              />}
              &nbsp;&nbsp;{!device.editingRef && <OverlayTrigger overlay={<Tooltip>Renommer</Tooltip>}><span className='text-primary' onClick={() => this.deviceEditing(i, j)} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={['fas', 'edit']} /></span></OverlayTrigger>}
              {device.editingRef && <OverlayTrigger overlay={<Tooltip>Valider</Tooltip>}><span className='text-success' onClick={() => this.deviceRename(i, j)} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={['fas', 'save']} /></span></OverlayTrigger>}
              &nbsp;&nbsp;{!device.editingRef && <OverlayTrigger overlay={<Tooltip>Désactiver</Tooltip>}><span className='text-danger' onClick={() => { if (window.confirm('Êtes-vous sûr ? Cet appareil ne sera plus en mesure d\'accéder à votre compte.')) this.deviceRemove(i, j) }} style={{ cursor: "pointer" }} ><FontAwesomeIcon icon={['fas', 'trash-alt']} /></span></OverlayTrigger>}
              {device.editingRef && <OverlayTrigger overlay={<Tooltip>Annuler</Tooltip>}><span className='text-danger' onClick={() => this.deviceCancel(i, j)} style={{ cursor: "pointer" }} ><FontAwesomeIcon icon={['fas', 'ban']} /></span></OverlayTrigger>}
            </span><br /></>
          })}
          </span>
        )
        //}
        return <Row key={i}
          style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem .5rem', marginBottom: '3px' }}>
          <Col className="col-auto align-self-center ">
            <QRCode value={process.env.REACT_APP_SITE_URL+"/qr?t=l&c="+license.license_key} />
          </Col>
          <Col>
            <h5>{type}</h5>
            <h6>{expiration}</h6>
            <span>Clé d'activation : <b>{license.license_key}</b></span><br />
            {devices}
          </Col>
        </Row>
      })
    }
  }

  renderDevice(device, index) {
    return (
      <ListGroup.Item key={index}>
        <Row>
          <Col md={8}>{device.uid}</Col>
          <Col md={4}>
            <FontAwesomeIcon
              color={device.active === 1 ? 'green' : 'red'}
              icon={['fas', 'circle']}
            />{' '}
            <Button
              variant={device.active === 1 ? 'danger' : 'success'}
              onClick={() => this.toggleActivation(device)}
            >
              {device.active === 1 ? 'Désactiver' : 'Activer'}
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  renderDevices() {
    if (this.state.currentLicense.noLicense) return null;
    return (
      <ListGroup variant="flush" style={{ width: '100%' }}>
        {this.state.currentLicense.devices.map(this.renderDevice.bind(this))}
      </ListGroup>
    );
  }

  renderCode(code, index) {
    return (
      <ListGroup.Item key={index}>
        <Row>
          <Col md={5}>
            <pre>{code.code}</pre>
          </Col>
          <Col md={7}>
            <Button
              variant="success"
              onClick={() => {
                const textField = document.createElement('textarea');
                textField.innerText = `${SITE_URL}/register/${code.code}`;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
              }}
            >
              Copier le lien
            </Button>{' '}
            <Button variant="danger" onClick={() => this.deleteCode(code)}>
              Supprimer
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  renderCodes() {
    return (
      <div>
        <ListGroup variant="flush" style={{ width: '100%' }}>
          {this.state.codes
            .filter(code => code.used === 0)
            .map(this.renderCode.bind(this))}
        </ListGroup>
        <div style={{ padding: '0.5em' }} />
        <Button variant="info" onClick={() => this.createCode()}>
          Cr&eacute;er un code
        </Button>
      </div>
    );
  }

  renderPro(pro, index) {
    return (
      <ListGroup.Item key={index}>
        <Row>
          <Col md={8}>{pro.mail}</Col>
          <Col md={4}>
            <Button variant="danger" onClick={() => this.deletePro(pro)}>
              D&eacute;sactiver
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  renderPros() {
    return (
      <ListGroup variant="flush" style={{ width: '100%' }}>
        {this.state.pros.map(this.renderPro.bind(this))}
      </ListGroup>
    );
  }

  render() {
    var title = 'Ma licence';
    if (this.props.account.type !== 'PRIVATE') title = 'Mes licences';
    return (
      <div>
        <Row className="homePadding justify-content-md-center">
          <Col md={6}>
            <Card>
              <Card.Header>{title}</Card.Header>
              <Card.Body>
                {this.state.loading ? (
                  <p>Chargement...</p>
                ) : (
                  this.renderCurrentLicenses()
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Licenses.propTypes = {
  token: PropTypes.string,
  email: PropTypes.string,
  history: PropTypes.object,
  account: PropTypes.object,
};
