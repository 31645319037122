import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default class ChangePassword extends Component {
  state = {
    error: 0,
    success: false,
    oldpwd: '',
    newpwd: '',
    newpwdCheck: '',
  };

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };
  onSubmit = async event => {
    event.preventDefault();
    if (
      this.state.newpwd === '' ||
      this.state.newpwdCheck === '' ||
      this.state.oldpwd === ''
    ) {
      await this.setState(
        produce(draft => {
          draft.success = false;
          draft.error = 3;
        }),
      );
      return;
    }
    if (this.state.newpwd !== this.state.newpwdCheck) {
      await this.setState(
        produce(draft => {
          draft.success = false;
          draft.error = 2;
        }),
      );
      return;
    }
    const update = await API.post(ENDPOINTS.passwordChange, this.props.token, {
      oldpwd: this.state.oldpwd,
      newpwd: this.state.newpwd,
    });
    if (update.code === 'OK') {
      await this.setState(
        produce(draft => {
          draft.error = 0;
          draft.success = true;
        }),
      );
      return;
    }
    await this.setState(
      produce(draft => {
        draft.success = false;
        draft.error = 1;
      }),
    );
  };

  render() {
    let errorMessage = '';
    switch (this.state.error) {
      case 1:
        errorMessage = 'Mauvais ancien mot de passe';
        break;
      case 2:
        errorMessage =
          'Les deux champs de nouveau mot de passe ne sont pas pareils';
        break;
      case 3:
        errorMessage = 'Tous les champs sont obligatoires';
        break;

      default:
        break;
    }
    return (
      <Form noValidate onSubmit={this.onSubmit}>
        {this.state.error > 0 && (
          <Form.Group controlId="formError">
            <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>
          </Form.Group>
        )}
        {this.state.success && (
          <Form.Group controlId="formError">
            <Form.Label style={{ color: 'green' }}>
              Mot de passe chang&eacute;
            </Form.Label>
          </Form.Group>
        )}
        <Form.Group controlId="formOldPwd">
          <Form.Label>Ancien mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Mot de passe"
            value={this.state.password}
            onChange={this.onChange('oldpwd')}
            isInvalid={this.state.error === 1 || this.state.error === 3}
          />
        </Form.Group>
        <Form.Group controlId="formNewPwd">
          <Form.Label>Nouveau mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Mot de passe"
            value={this.state.password}
            onChange={this.onChange('newpwd')}
            isInvalid={this.state.error === 2 || this.state.error === 3}
          />
        </Form.Group>
        <Form.Group controlId="formNewPwd2">
          <Form.Label>
            R&eacute;p&eacute;tez votre nouveau mot de passe
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Mot de passe"
            value={this.state.password}
            onChange={this.onChange('newpwdCheck')}
            isInvalid={this.state.error === 2 || this.state.error === 3}
          />
        </Form.Group>
        <Button
          variant={
            this.state.success
              ? 'success'
              : this.state.error > 0
                ? 'danger'
                : 'primary'
          }
          type="submit"
          block
        >
          Changer mot de passe
        </Button>
      </Form>
    );
  }
}

ChangePassword.propTypes = {
  token: PropTypes.string,
};
