import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Account from './account';
import AccountEdit from './accountEdit';
import Play from './play';
import Download from './download';
import Settings from './settings';
import Licenses from './licenses';
import CGU from '../cgu';
import PDC from '../pdc';
import Admin from './admin';
import Evaluations from './evaluations';
import Exercices from './exercices';
import Profile from './profile';

import logo from 'assets/logo.png';

class Home extends Component {
  goHome() {
    this.props.history.push('/home');
  }
  play() {
    this.props.history.push('/home/play');
  }
  download() {
    this.props.history.push('/home/download');
  }

  settings() {
    this.props.history.push('/home/admin/settings');
  }

  licenses() {
    this.props.history.push('/home/licenses');
  }

  cgu() {
    this.props.history.push('/home/cgu');
  }

  pdc() {
    this.props.history.push('/home/pdc');
  }

  admin() {
    this.props.history.push('/home/admin');
  }

  evaluations() {
    this.props.history.push('/home/evaluations');
  }

  exercices() {
    this.props.history.push('/home/exercices');
  }
  profile() {
    this.props.history.push('/home/profile');
  }

  render() {
    const { location } = this.props;
    let active = 'home';
    switch (location.pathname) {
      case '/home/play':
        active = 'play';
        break;
      case '/home/download':
        active = 'download';
        break;
      case '/home/licenses':
        active = 'licenses';
        break;
      case '/home/cgu':
        active = 'cgu';
        break;
      case '/home/pdc':
        active = 'pdc';
        break;
      case '/home/admin':
        active = 'admin';
        break;

      default:
        break;
    }
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="md"
          bg="dark"
          variant="dark"
          fixed="top"
        >
          <Navbar.Brand href="/home">
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {' MagikEduk'}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navitems" />
          <Navbar.Collapse
            id="navitems"
            expand="lg"
            className="justify-content-end"
          >
            <Nav className="justify-content-end" defaultActiveKey={active}>
              <Nav.Item>
                <Nav.Link eventKey="home" onSelect={() => this.goHome()}>
                  Tableau de bord
                </Nav.Link>
              </Nav.Item>
              {false && this.props.account.type !== 'PRIVATE' && (
                <Nav.Item>
                  <Nav.Link
                    eventKey="stats"
                    href={`https://statistiques.magikeduk.com/#/user/${this.props.token}/${this.props.clientId}`}
                    target="_blank"
                  >
                    Statistiques
                  </Nav.Link>
                </Nav.Item>
              )}
              {!['Compte GAR', 'Compte MASKOTT', 'Compte AREN+'].includes(this.props.email) && <Nav.Item>
                <Nav.Link
                  eventKey="licenses"
                  onSelect={() => this.licenses()}
                >
                  Mes licences
                </Nav.Link>
              </Nav.Item>
              }
              <Nav.Item>
                <Nav.Link eventKey="download" onSelect={() => this.download()}>
                  Jouer
                </Nav.Link>
              </Nav.Item>
              {this.props.account.type === 'ADMIN' && (
                <Nav.Item>
                  <Nav.Link eventKey="admin" onSelect={() => this.admin()}>
                    Administration
                  </Nav.Link>
                </Nav.Item>
              )}
              {this.props.account.type === 'ADMIN' && (
                <Nav.Item>
                  <Nav.Link eventKey="settings" onSelect={() => this.settings()}>
                    Réglages
                  </Nav.Link>
                </Nav.Item>
              )}
              {this.props.account.type !== 'PRIVATE' && (
                <NavDropdown title="Résultats des élèves" id="basic-nav-dropdown">
                  <NavDropdown.Item eventKey="evaluations" onSelect={() => this.evaluations()}>Evaluations</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="exercices" onSelect={() => this.exercices()}>Exercices</NavDropdown.Item>
                </NavDropdown>
              )}
              <NavDropdown title={this.props.email} id="basic-nav-dropdown">
                <NavDropdown.Item eventKey="profile" onSelect={() => this.profile()}>Mon compte</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="logout" onSelect={() => this.props.logout()}>D&eacute;connexion</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route
            exact
            path="/home"
            render={() => <Account token={this.props.token} account={this.props.account} email={this.props.email} />}
          />
          <Route path="/home/play" render={() => <Play />} />
          <Route path="/home/download" render={() => <Download history={this.props.history} token={this.props.token} account={this.props.account} email={this.props.email} />} />
          <Route
            path="/home/licenses"
            render={() => (
              <Licenses history={this.props.history} token={this.props.token} account={this.props.account} email={this.props.email} />
            )}
          />
          <Route path="/home/cgu" render={() => <CGU home />} />
          <Route path="/home/pdc" render={() => <PDC home />} />
          <Route path="/home/profile" render={() => <Profile token={this.props.token} account={this.props.account} email={this.props.email} />} />
          {this.props.account.type === 'ADMIN' && (
            <Route
              path="/home/admin/account/:tag([A-Z0-9]+)"
              render={() => (
                <AccountEdit history={this.props.history} token={this.props.token} account={this.props.account} />
              )}
            />
          )}
          {this.props.account.type === 'ADMIN' && (
            <Route
              path="/home/admin/settings"
              render={() => (
                <Settings history={this.props.history} token={this.props.token} account={this.props.account} />
              )}
            />
          )}
          {this.props.account.type === 'ADMIN' && (
            <Route
              path="/home/admin"
              render={() => (
                <Admin history={this.props.history} token={this.props.token} account={this.props.account}
                  setToken={this.props.setToken} />
              )}
            />
          )}
          {this.props.account.type !== 'PRIVATE' && (
            <Route
              path="/home/evaluations"
              render={() => (
                <Evaluations history={this.props.history} token={this.props.token} account={this.props.account} />
              )}
            />
          )}
          {this.props.account.type !== 'PRIVATE' && (
            <Route
              path="/home/exercices"
              render={() => (
                <Exercices history={this.props.history} token={this.props.token} account={this.props.account} />
              )}
            />
          )}
        </Switch>
      </div>
    );
  }
}

Home.propTypes = {
  token: PropTypes.string,
  email: PropTypes.string,
  account: PropTypes.object,
  clientId: PropTypes.string,
  logout: PropTypes.func,
};

export default withRouter(Home);
