import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Settings extends Component {
  state = {
    phonemes: null,
    phonemes_bckp: null,
    difficulties: null,
    difficulties_bckp: null,
    saving: false,
    selected_phoneme: 37
  };

  componentDidMount() {
    if (this.props.account.type !== 'ADMIN') {
      this.props.history.push('/home');
      return;
    } else {
      API.get(ENDPOINTS.difficulties, this.props.token).then(result => {
        this.setState({
          difficulties: result.difficulties,
          difficulties_bckp: JSON.stringify(result.difficulties)
        });
      });
      API.get(ENDPOINTS.phonemes, this.props.token).then(result => {
        this.setState({
          phonemes: result.phonemes,
          phonemes_bckp: JSON.stringify(result.phonemes)
        });
      });
    }
  }

  checkDifficulty(phoneme_id, task_type, task_idx, difficulty_id) {
    var phonemes = this.state.phonemes
    if (typeof phonemes[phoneme_id].tasks[task_type][task_idx].difficulties === 'undefined') phonemes[phoneme_id].tasks[task_type][task_idx].difficulties = []
    var idx = phonemes[phoneme_id].tasks[task_type][task_idx].difficulties.indexOf(difficulty_id)
    if (idx === -1) {
      phonemes[phoneme_id].tasks[task_type][task_idx].difficulties.push(difficulty_id)
    } else {
      phonemes[phoneme_id].tasks[task_type][task_idx].difficulties.splice(idx, 1)
    }
    this.setState({
      phonemes: phonemes
    });
  }

  setDifficulty = (difficulty_id, type) => event => {
    var difficulties = this.state.difficulties
    difficulties[difficulty_id][type] = event.target.value;

    this.setState({
      difficulties
    });
  }

  setTaskWord = (phoneme_id, activity_id, task_id) => event => {
    var phonemes = this.state.phonemes
    var task = phonemes[phoneme_id].tasks[activity_id][task_id]
    var word = event.target.value

    task.word = word.replaceAll('*', '').split('-')
    task.fixedChar = []
    var tmp = word.replaceAll('-', '')
    var index = tmp.indexOf('*')
    while (index !== -1) {
      task.fixedChar.push(index - 1)
      tmp = tmp.replace('*', '')
      index = tmp.indexOf('*')
    }
    this.setState({
      phonemes
    });
  }

  setTaskSounds = (phoneme_id, activity_id, task_id) => event => {
    var phonemes = this.state.phonemes
    var task = phonemes[phoneme_id].tasks[activity_id][task_id]
    var sounds = event.target.value

    task.sounds = sounds.split('-');
    for (var i = 0; i < task.sounds.length; i++) {
      task.sounds[i] = 'son_' + task.sounds[i]
    }
    this.setState({
      phonemes
    });
  }

  savePhonemes = () => {
    this.setState({
      saving: true
    });
    API.post(ENDPOINTS.phonemes, this.props.token, {
      phonemes: this.state.phonemes
    }).then(result => {
      this.setState({
        saving: false,
        phonemes_bckp: JSON.stringify(result.phonemes)
      });
    });
  }

  saveDifficulties = () => {
    this.setState({
      saving: true
    });
    API.post(ENDPOINTS.difficulties, this.props.token, {
      difficulties: this.state.difficulties
    }).then(result => {
      this.setState({
        saving: false,
        difficulties: result.difficulties,
        difficulties_bckp: JSON.stringify(result.difficulties)
      });
    });
  }

  addDifficulty = () => {
    var difficulties = this.state.difficulties
    difficulties.push({ id: 'new', title: '-' })

    this.setState({
      difficulties
    });
  }

  addTask = (phoneme_id, activity_id) => {
    var phonemes = this.state.phonemes
    var tasks = phonemes[phoneme_id].tasks[activity_id]
    console.log(tasks, tasks.length)
    tasks.push({ id: tasks.length ? tasks[tasks.length - 1].id + 1 : 1, word: [""], fixedChar: [] })

    this.setState({
      phonemes
    });
  }

  toValueA4 = (task) => {
    var charIdx = -1;
    var res = ''
    task.word.forEach(chars => {
      chars.split('').forEach(char => {
        charIdx++
        res += char
        if (task.fixedChar.includes(charIdx)) {
          res += '*'
        }
      });
      res += '-'
    });
    return res.substr(0, res.length - 1);
  }

  toValueA4Sound = (task) => {
    var res = ''
    if (task.sounds && task.word.length === task.sounds.length) {
      task.sounds.forEach(sound => {
        res += sound.substr(4, sound.length - 1)
        res += '-'
      });
    } else {
      task.word.forEach(chars => {
        res += chars
        res += '-'
      });
    }
    return res.substr(0, res.length - 1);
  }

  changeSelectedPhoneme = event => {
    this.setState({
      selected_phoneme: parseInt(event.target.getAttribute('data-rb-event-key'))
    });
  }
  render() {
    return (
      <Row className="homePadding justify-content-md-center">
        <Col md={12}>
          <Card>
            <Card.Header>Difficultés</Card.Header>
            <Card.Body>
              {this.state.difficulties && <Button variant={JSON.stringify(this.state.difficulties) !== this.state.difficulties_bckp ? 'primary' : 'secondary'} style={{ float: 'right' }} disabled={JSON.stringify(this.state.difficulties) === this.state.difficulties_bckp || this.state.saving} onClick={this.saveDifficulties}>Enregistrer les modifications</Button>}
              {this.state.difficulties && this.state.difficulties.map((difficulty, i) =>
                <div key={i}>{difficulty.id}. <input
                  type="text"
                  defaultValue={difficulty.title}
                  onBlur={this.setDifficulty(i, 'title')}
                />
                </div>
              )}
              <OverlayTrigger overlay={<Tooltip>Ajouter une difficulté</Tooltip>}><span className='text-success' onClick={() => this.addDifficulty()} style={{ cursor: "pointer" }} ><FontAwesomeIcon icon={['fas', 'plus-square']} /></span></OverlayTrigger>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <Card.Header>Phonèmes</Card.Header>
            <Card.Body>
              <Tab.Container activeKey={this.state.selected_phoneme}>
                <Row>
                  <Col sm={3} style={{ borderRight: "solid 1px #ccc", borderBottom: "solid 1px #ccc", height: "60vh", overflowY: "scroll" }}>
                    <Nav variant="pills" className="flex-column">
                      {this.state.phonemes && this.state.phonemes.map((phoneme, i) => <Nav.Item key={i}>
                        <Nav.Link eventKey={phoneme.id} onClick={this.changeSelectedPhoneme}>{phoneme.son} ({phoneme.id})</Nav.Link>
                      </Nav.Item>)}
                    </Nav>
                  </Col>
                  <Col sm={9} style={{ height: "60vh", overflowY: "scroll" }}>
                    {this.state.phonemes && <Button variant={JSON.stringify(this.state.phonemes) !== this.state.phonemes_bckp ? 'primary' : 'secondary'} style={{ float: 'right' }} disabled={JSON.stringify(this.state.phonemes) === this.state.phonemes_bckp || this.state.saving} onClick={this.savePhonemes}>Enregistrer les modifications</Button>}
                    <Tab.Content>
                      {this.state.phonemes && this.state.phonemes.map((phoneme, i) => {
                        if (phoneme.id !== this.state.selected_phoneme) return <Tab.Pane eventKey={phoneme.id} key={i}>Chargement</Tab.Pane>
                        if (typeof phoneme.tasks[3] === 'undefined') {
                          phoneme.tasks[3] = [];
                        }
                        return <Tab.Pane eventKey={phoneme.id} key={i}>
                          <Tabs
                            defaultActiveKey="activity_1"
                          >
                            <Tab eventKey="activity_1" title="Activité 1">
                              {phoneme.tasks[0].map((task, j) => <div key={j} style={{ borderBottom: "1px solid #ccc" }} > {j + 1}. {task.trueword} - {task.falseword}<br /> {this.state.difficulties.map((difficulty, k) =>
                                <Button key={k} style={{ margin: '5px' }} variant={task.difficulties && task.difficulties.indexOf(difficulty.id) !== -1 ? 'primary' : 'secondary'} size="sm" onClick={() => this.checkDifficulty(i, 0, j, difficulty.id)}>
                                  {difficulty.title}
                                </Button>
                              )}</div>)}
                            </Tab>
                            <Tab eventKey="activity_2" title="Activité 2">
                              {phoneme.tasks[1].map((task, j) => <div key={j} style={{ borderBottom: "1px solid #ccc" }} > {j + 1}. {task.word} [{task.hidechar.map((idx, k) => { return task.word[idx] })}]<br /> {this.state.difficulties.map((difficulty, k) =>
                                <Button key={k} style={{ margin: '5px' }} variant={task.difficulties && task.difficulties.indexOf(difficulty.id) !== -1 ? 'primary' : 'secondary'} size="sm" onClick={() => this.checkDifficulty(i, 1, j, difficulty.id)}>
                                  {difficulty.title}
                                </Button>
                              )}</div>)}
                            </Tab>
                            <Tab eventKey="activity_3" title="Activité 3">
                              {phoneme.tasks[2].map((task, j) => <div key={j} style={{ borderBottom: "1px solid #ccc" }} > {j + 1}. {task.sylpos.map((tab, k) => {
                                return tab.map((idx, l) => { return task.word[idx] }).join('')
                              }).join('/')}<br /> {this.state.difficulties.map((difficulty, k) =>
                                <Button key={k} style={{ margin: '5px' }} variant={task.difficulties && task.difficulties.indexOf(difficulty.id) !== -1 ? 'primary' : 'secondary'} size="sm" onClick={() => this.checkDifficulty(i, 2, j, difficulty.id)}>
                                  {difficulty.title}
                                </Button>
                              )}</div>)}
                            </Tab>
                            <Tab eventKey="activity_4" title="Activité 4">
                              {phoneme.tasks[3].map((task, j) =>
                                <div key={j} style={{ borderBottom: "1px solid #ccc" }} > <div className="input-group">
                                  <div className="input-group-prepend"><span className="input-group-text" id="basic-addon1">{j + 1}. </span></div><input
                                    className="form-control"
                                type="text"
                                defaultValue={this.toValueA4(task)}
                                    onBlur={this.setTaskWord(i, 3, j)}
                                  />
                                </div><div className="input-group">
                                    <div className="input-group-prepend"><span className="input-group-text" id="basic-addon1">Sons</span></div><input
                                      className="form-control"
                                      type="text"
                                      value={this.toValueA4Sound(task)}
                                      onChange={this.setTaskSounds(i, 3, j)}
                                    />
                                  </div>{this.state.difficulties.map((difficulty, k) =>
                                  <Button key={k} style={{ margin: '5px' }} variant={task.difficulties && task.difficulties.indexOf(difficulty.id) !== -1 ? 'primary' : 'secondary'} size="sm" onClick={() => this.checkDifficulty(i, 3, j, difficulty.id)}>
                                    {difficulty.title}
                                  </Button>
                                )}</div>
                              )}
                              <OverlayTrigger overlay={<Tooltip>Ajouter une tâche</Tooltip>}><span className='text-success' onClick={() => this.addTask(i, 3)} style={{ cursor: "pointer" }} ><FontAwesomeIcon icon={['fas', 'plus-square']} /></span></OverlayTrigger>
                            </Tab>
                          </Tabs>
                        </Tab.Pane>
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

// <ListGroup.Item>
// <a href={this.state.paths.windows} download>
//   <h3>
//     <FontAwesomeIcon icon={['fab', 'windows']} /> Windows
//   </h3>
// </a>
// </ListGroup.Item>
// <ListGroup.Item>
// <a href={this.state.paths.mac} download>
//   <h3>
//     <FontAwesomeIcon icon={['fab', 'apple']} /> MacOS
//   </h3>
// </a>
// </ListGroup.Item>

Settings.propTypes = {
  token: PropTypes.string,
};
