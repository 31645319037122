import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Card from 'react-bootstrap/Card';

export default class CGU extends Component {
  state = {
    content: 'Veuillez patienter',
  };

  componentDidMount() {
    fetch(`${origin}/cgu.md`)
      .then(response => response.text())
      .then(content => {
        this.setState({ content });
      });
  }

  render() {
    return (
      <div
        className={(this.props.home && 'homePadding') || undefined}
        style={{ marginTop: '1em' }}
      >
        <Card style={{ position: 'fixed', top: '8vh', bottom: '8vh', maxWidth: '1200px', overflowY: 'scroll' }}>
          <Card.Body >
            <ReactMarkdown source={this.state.content} />
          </Card.Body>
        </Card>
      </div >
    );
  }
}

CGU.propTypes = {
  home: PropTypes.bool,
};
