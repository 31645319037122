import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
import {
  API
} from 'lib/rest';
import {
  ENDPOINTS
} from 'config/network';
import { withCookies } from 'react-cookie';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class PersonalInfo extends Component {
  state = {
    addressLine1: '',
    addressLine2: '',
    CP: '',
    city: '',
    name: '',
    surname: '',
  };
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState(
      produce(draft => {
        return this.props.account.personnal_info
      })
    );
    if (this.props.onInfoLoad) {
      this.props.onInfoLoad(this.props.account.personnal_info);
    }
  };

  saveData = async () => {
    const req = await API.post(ENDPOINTS.userinfo, this.props.token, {
      personnal_info: this.state,
    });
    if (req && req.code === 'OK') {
      alert('Informations mises a jour');
      this.props.account.personnal_info = this.state
      this.props.cookies.set('mge-account', this.props.account, { path: '/' });
      return;
    }
    alert('Erreur, veuillez reessayer');
  };

  onChange = field => event => {
    const value = event.target.value;
    this.setState(
      produce(draft => {
        draft[field] = value;
      }),
    );
  };

  renderNameForm() {
    return (
      <Form noValidate>
        <Form.Group controlId="formName">
          <Form.Label>Pr&eacute;nom</Form.Label>
          <Form.Control
            placeholder="Pr&eacute;nom"
            value={this.state.name}
            onChange={this.onChange('name')}
          />
        </Form.Group>
        <Form.Group controlId="formSurname">
          <Form.Label>Nom</Form.Label>
          <Form.Control
            placeholder="Nom"
            value={this.state.surname}
            onChange={this.onChange('surname')}
          />
        </Form.Group>
      </Form>
    );
  }

  renderAddressForm() {
    return (
      <Form noValidate>
        <Form.Group controlId="formAddress1">
          <Form.Label>Adresse ligne 1</Form.Label>
          <Form.Control
            placeholder=""
            value={this.state.addressLine1}
            onChange={this.onChange('addressLine1')}
          />
        </Form.Group>
        <Form.Group controlId="formAddress2">
          <Form.Label>Adresse ligne 2</Form.Label>
          <Form.Control
            placeholder=""
            value={this.state.addressLine2}
            onChange={this.onChange('addressLine2')}
          />
        </Form.Group>
        <Form.Group controlId="formCP">
          <Form.Label>Code postal</Form.Label>
          <Form.Control
            placeholder=""
            value={this.state.CP}
            onChange={this.onChange('CP')}
          />
        </Form.Group>
        <Form.Group controlId="formCity">
          <Form.Label>Ville</Form.Label>
          <Form.Control
            placeholder=""
            value={this.state.city}
            onChange={this.onChange('city')}
          />
        </Form.Group>
      </Form>
    );
  }

  render() {
    if (this.props.raw)
      return (
        <div>
          <div>
            <b>Mon nom</b>
            {this.renderNameForm()}
          </div>
          <div>
            <b>Mon adresse</b>
            {this.renderAddressForm()}
          </div>
        </div>
      );
    return (
      <React.Fragment>
        <Card.Body>
          <Card.Title>Mon nom</Card.Title>
          {this.renderNameForm()}
        </Card.Body>
        <Card.Body>
          <Card.Title>Mon adresse</Card.Title>
          {this.renderAddressForm()}
        </Card.Body>
        <Card.Body>
          <Button variant={'primary'} block onClick={() => this.saveData()}>
            Enregistrer
          </Button>
        </Card.Body>
      </React.Fragment>
    );
  }
}
PersonalInfo.propTypes = {
  raw: PropTypes.bool,
  token: PropTypes.string,
  onInfoLoad: PropTypes.func,
};

export default withCookies(PersonalInfo)