
import React from 'react';

import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const EmailCheck = (email) => {
  return emailRegex.test(email)
}

export const AccountNew = (cb) =>
  <OverlayTrigger overlay={<Tooltip>Nouveau Compte</Tooltip>}>
    <span className='text-success' style={{ cursor: "pointer" }} onClick={cb}><FontAwesomeIcon icon={['fas', 'user-plus']} /></span>
  </OverlayTrigger>

export const AccountTypeIcon = (type) => {
  let icon = ['far', 'user']
  let title = 'Autre compte'
  let txtClass = 'text-default'
  switch (type) {
    case 'PRIVATE': { txtClass = 'text-primary'; icon = ['fas', 'house-user']; title = 'Compte Personnel'; break; }
    case 'TEACHER': { txtClass = 'text-success'; icon = ['fas', 'chalkboard-teacher']; title = 'Compte Enseignant'; break; }
    case 'SCHOOL': { txtClass = 'text-info'; icon = ['fas', 'school']; title = 'Compte Ecole'; break; }
    case 'TOWNHALL': { txtClass = 'text-secondary'; icon = ['fas', 'gopuram']; title = 'Compte Mairie'; break; }
    case 'ADMIN': { txtClass = 'text-warning'; icon = ['fas', 'user-cog']; title = 'Compte Administrateur'; break; }
    default: { txtClass = 'text-danger'; icon = ['fas', 'user-astronaut']; title = 'Compte non identifié'; break; }
  }
  return <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
    <span className={txtClass} style={{ cursor: "help" }}><FontAwesomeIcon icon={icon} /></span>
  </OverlayTrigger>
}
export const GroupTypeIcon = (type) => {
  let icon = ['far', 'user']
  let title = 'Autre groupe'
  let txtClass = 'text-default'
  switch (type) {
    case 'GROUP': { txtClass = 'text-primary'; icon = ['fas', 'users']; title = 'Groupe'; break; }
    case 'CLASS': { txtClass = 'text-success'; icon = ['fas', 'chalkboard-teacher']; title = 'Classe'; break; }
    case 'SCHOOL': { txtClass = 'text-info'; icon = ['fas', 'school']; title = 'Ecole'; break; }
    default: { txtClass = 'text-primary'; icon = ['fas', 'users']; title = 'Groupe'; break; }
  }
  return <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
    <span className={txtClass} style={{ cursor: "help" }}><FontAwesomeIcon icon={icon} /></span>
  </OverlayTrigger>
}
export const AccountDateFormat = (date, extended = false) => {
  if (extended) {
    return <OverlayTrigger overlay={<Tooltip>{date && moment(date).fromNow()}</Tooltip>}>
      <span style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'history']} /> {moment(date).format('L LT')} </span>
    </OverlayTrigger>
  } else {
    return <OverlayTrigger overlay={<Tooltip>{date && moment(date).format('LLLL')}</Tooltip>}>
      <span style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'history']} /> {moment(date).fromNow()} </span>
    </OverlayTrigger>
  }
}
export const AccountConnexions = (connexions, extended = false, resetPassword, loginAsFn) => {
  if (!connexions || connexions.length < 1) {
    return <OverlayTrigger overlay={<Tooltip>Progression non sauvegardée</Tooltip>}>
      <span className="text-danger" style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'user-slash']} />{extended ? ' Progression non sauvegardée' : ''}</span>
    </OverlayTrigger>
  } else {
    return connexions.map((connexion, i) => {
      var icon = (
        <OverlayTrigger key={i} overlay={<Tooltip>{connexion.identifier}</Tooltip>}>
          <span className="text-primary" style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'user-circle']} /></span>
        </OverlayTrigger>)
      if (extended) {
        var resetLink = <span>{connexion.data.resetLink ? <Button variant="link" href={"/reset/" + connexion.data.resetLink}><FontAwesomeIcon icon={['fas', 'globe']} /> Accéder au lien de réinitialisation</Button> : <Button variant="link" onClick={() => resetPassword(connexion.identifier)}><FontAwesomeIcon icon={['fas', 'paper-plane']} /> Envoyer un lien de réinitialisation</Button>}</span>
        var loginAs = <Button variant="link" onClick={() => loginAsFn(connexion.identifier)}><FontAwesomeIcon icon={['fas', 'user-secret']} /> Se connecter en tant que</Button>
        return <p key={i}>{icon} {connexion.identifier} {resetLink} {loginAs}</p>
      } else {
        return icon
      }
    })
  }
}
export const LicenseType = (license) => {
  return <OverlayTrigger overlay={license.type === 'PERSO' ? <Tooltip>LICENCE PERSONNELLE</Tooltip> : <Tooltip>LICENCE PROFESSIONNELLE</Tooltip>}>
    <span style={{ cursor: "help" }}><FontAwesomeIcon icon={license.type === 'PERSO' ? ['fas', 'user'] : ['fas', 'chalkboard-teacher']} /></span>
  </OverlayTrigger>
}
export const LicenseExpiration = (license) => {
  return <OverlayTrigger overlay={new Date(license.expiration) < new Date() ? <Tooltip>expirée depuis le {moment(license.expiration).format('LLLL')}</Tooltip> : <Tooltip>valide jusqu'au {moment(license.expiration).format('LLLL')}</Tooltip>}>
    <span className={new Date(license.expiration) < new Date() ? 'text-danger' : 'text-success'} style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'clock']} /></span>
  </OverlayTrigger>
}
export const LicenseKey = (license) => {
  return <OverlayTrigger overlay={<Tooltip>Clé d'activation : <b>{license.license_key}</b></Tooltip>}>
    <span style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'key']} onClick={copyToClipboard(license.license_key)} /></span>
  </OverlayTrigger>
}
export const LicenseDevices = (license) => {
  return <OverlayTrigger overlay={<Tooltip>Activée sur {license.devices.length} appareil{license.devices.length > 1 && "s"} (maximum: {license.max_devices}) <br />{license.devices.map((device, j) => {
    var name = (device.editingRef || device.name ? device.name : 'Appareil inconnu')
    return <span key={j}>&nbsp;&nbsp;&nbsp;&nbsp;
  <FontAwesomeIcon icon={['fas', 'check-square']} />
  &nbsp;{name}<br />
    </span>
  })}</Tooltip>}>
    <span className={license.devices.length >= license.max_devices ? 'text-default' : 'text-info'} style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'network-wired']} /><Badge>{license.devices.length}/{license.max_devices}</Badge></span>
  </OverlayTrigger>
}
export const Device = (device) => {
  var name = (device.editingRef || device.name ? device.name : 'Appareil inconnu')
  return <OverlayTrigger overlay={<Tooltip>{name}</Tooltip>}>
    <span style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'tablet-alt']} /></span>
  </OverlayTrigger>
}
export const AccountPersonnalInformations = (account) => {
  if (account != null) {
    let address;
    let name;
    if (account.personnal_info) {
      try {
        const info = account.personnal_info;
        if (info.name && info.name !== '') {
          name = `${info.name} ${info.surname}`;
        }
        address = <div>
          {info.addressLine1}
          {info.addressLine2 && <br />}
          {info.addressLine2}
          <br />
          {info.CP} {info.city}
        </div>

      } catch (e) {
        console.error(e);
      }
    }
    return <div><h4>{name && <div>{name}</div>}</h4>
      {address && <div>{address}</div>}</div>
  }
  return ""
}
export const Product = (product) => {
  let icon, title, txtClass
  switch (product) {
    case 'pack_70_ingr': { txtClass = 'text-info'; icon = ['fas', 'box-open']; title = 'Sac de 70 champidors'; break; }
    case 'pack_215_ingr': { txtClass = 'text-primary'; icon = ['fas', 'box-open']; title = 'Seau de 215 champidors'; break; }
    case 'pack_600_ingr': { txtClass = 'text-success'; icon = ['fas', 'box-open']; title = 'Caisse de 600 champidors'; break; }
    case 'pack_1950_ingr': { txtClass = 'text-warning'; icon = ['fas', 'box-open']; title = 'Montage de 1950 champidors'; break; }
    case 'sub_7_days': { txtClass = 'text-info'; icon = ['fas', 'hourglass']; title = 'Pack 7 jours'; break; }
    case 'sub_30_days': { txtClass = 'text-primary'; icon = ['fas', 'hourglass']; title = 'Pack 1 mois'; break; }
    case 'sub_90_days': { txtClass = 'text-success'; icon = ['fas', 'hourglass']; title = 'Pack 3 mois'; break; }
    case 'sub_365_days': { txtClass = 'text-warning'; icon = ['fas', 'hourglass']; title = 'Pack 1 an'; break; }
    default: { txtClass = 'text-danger'; icon = ['fas', 'question-circle']; title = 'Produit inconnu'; break; }
  }
  return <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
    <span className={txtClass} style={{ cursor: "help" }}><FontAwesomeIcon icon={icon} /></span>
  </OverlayTrigger>
}
export const jsonToHtml = (prop, data) => {
  let content = []
  if (typeof data !== 'object') {
    try {
      data = JSON.parse(data)
    } catch (e) {

    }
  }
  if (typeof data === 'object') {
    for (var p in data) {
      content.push(jsonToHtml(p, data[p]))
    }
  } else {
    content.push(data)
  }
  return <div style={{ margin: 0, padding: 0, textAlign: 'left', maxHeight: '40vh', maxWidth: '60vw', overflow: 'auto' }}><b>{prop}</b><div style={{ paddingLeft: '4px', borderLeft: '1px dotted #888' }}>{content.map((c, i) => <span key={i}>{c}</span>)}</div></div>
}
export const AdditionnalData = (data) => {
  if (typeof data !== 'object') {
    try {
      data = JSON.parse(data)
    } catch (e) {

    }
  }
  var props = []
  for (var prop in data) {
    if (data[prop] !== null) {
      let icon
      let txtClass = 'text-default'
      let trigger = ['focus', 'hover']
      let title = jsonToHtml(prop, data[prop])
      switch (prop) {
        case 'receipt': { trigger = 'click'; icon = ['fas', 'receipt']; break; }
        case 'validated_data': { trigger = 'click'; txtClass = 'text-primary'; icon = ['fas', 'info-circle']; break; }
        case 'data': { trigger = 'click'; txtClass = 'text-primary'; icon = ['fas', 'info-circle']; break; }
        case 'purchase_data': { trigger = 'click'; txtClass = 'text-success'; icon = ['fas', 'info-circle']; break; }
        case 'error_data': { trigger = 'click'; txtClass = 'text-danger'; icon = ['fas', 'info-circle']; break; }
        case 'Version': { icon = ['fas', 'code-branch']; break; }
        case 'Platform': { icon = ['fas', 'mobile-alt']; break; }
        case 'DeviceUID': { icon = ['fas', 'fingerprint']; break; }
        case 'OperatingSystem': { icon = ['fas', 'microchip']; break; }
        case 'DeviceModel': { icon = ['fas', 'copyright']; break; }
        case 'DeviceName': { icon = ['fas', 'tablet-alt']; break; }
        case 'iOSID':
        case 'token': { break }
        case 'Resolution': { icon = ['fas', 'expand-alt']; break; }
        case 'ip': { icon = ['fas', 'location-arrow']; break; }
        case 'headers': { trigger = 'click'; icon = ['fas', 'globe']; break; }
        default: { trigger = 'click'; icon = ['fas', 'question-circle']; break; }
      }
      if (icon) {
        props.push(<OverlayTrigger placement='top' trigger={trigger} overlay={<Popover style={{ padding: '2px' }}>{title}</Popover>} >
          <span className={txtClass} style={{ cursor: "help" }}><FontAwesomeIcon icon={icon} />&nbsp;</span>
        </OverlayTrigger >)
      }
    }
  }
  return <span>{props.map((p, i) => p)}</span>
}
export const ActivityLog = (log, i) => {
  let icon, title, txtClass, action, device, connexion, license, product
  switch (log.action) {
    case 'DASHBOARD_LOGIN': { txtClass = 'text-info'; icon = ['fas', 'tachometer-alt']; title = 'Connexion au dashboard'; break; }
    case 'DEVICE_LOGIN': { txtClass = 'text-primary'; icon = ['fas', 'gamepad']; title = 'Connexion au jeu'; break; }
    case 'PURCHASE_SUCCEED': { txtClass = 'text-success'; icon = ['fas', 'credit-card']; title = 'Paiement effectué'; break; }
    case 'PURCHASE_FAILED': { txtClass = 'text-danger'; icon = ['fas', 'credit-card']; title = 'Tentative de paiement'; break; }
    case 'PRODUCT_SUCCEED': { txtClass = 'text-success'; icon = ['fas', 'shopping-cart']; title = 'Achat effectué'; break; }
    case 'PRODUCT_FAILED': { txtClass = 'text-danger'; icon = ['fas', 'shopping-cart']; title = 'Tentative d\'achat'; break; }
    default: { txtClass = 'text-danger'; icon = ['fas', 'question-circle']; title = 'Activitée inconnue'; break; }
  }
  action = <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
    <span className={txtClass} style={{ cursor: "help" }}><FontAwesomeIcon icon={icon} />&nbsp;&nbsp;</span>
  </OverlayTrigger>

  if (log.connexion) {
    connexion = <span>{AccountConnexions([log.connexion])}&nbsp;&nbsp;</span>
  }
  if (log.device) {
    device = <span>{Device(log.device)}&nbsp;&nbsp;</span>
  }
  if (log.license) {
    license = <span>{LicenseKey(log.license)}&nbsp;&nbsp;</span>
  }
  if (log.product) {
    product = <span>{Product(log.product)}&nbsp;&nbsp;</span>
  }
  return <tr key={i} style={{ borderBottom: '1px solid #ccc' }}><td style={{ fontSize: '0.8em' }}>{AccountDateFormat(log.date)}</td><td>{action}{connexion}{device}{license}{product}</td><td>{AdditionnalData(log.data)}</td></tr>
}

export const AccountComment = (comment, i) => {
  return <tr key={i} style={{ borderBottom: '1px solid #ccc' }}><td style={{ fontSize: '0.8em' }}>{AccountDateFormat(comment.date)}</td><td>{comment.author && comment.author.personnal_info ? comment.author.personnal_info.name : ''}</td><td style={{ whiteSpace: 'pre-wrap' }}>{comment.comment}</td></tr>
}

export const AccountCard = (props) => {
  let { account, index, admin } = props
  let mail = account.connexions.length > 0 ? <b>{account.connexions[0].identifier}</b> : ''
  let name = "-";
  if (account.personnal_info) {
    try {
      const info = account.personnal_info;
      if (info.name && info.name !== '') {
        name = `${info.name} ${info.surname}`;
      }
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <Col sm='12' md='6' lg='4' xl='3' style={{ padding: '3px' }}>
      <ListGroup.Item
        key={index}
        type="button"
        action
        active={account === admin.state.account}
        onClick={() => { admin.openModal(account) }}
        style={{ border: '1px solid #666', borderRadius: '5px', padding: '.5rem 1rem' }}
      >
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{AccountTypeIcon(account.type)} {mail}</div>
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{name}</div>
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}><span style={{ float: 'left' }}>{AccountConnexions(account.connexions)}</span><span style={{ float: 'right' }}><i>{AccountDateFormat(account.creation_date, true)}</i></span></div>
      </ListGroup.Item>
    </Col>
  );
}